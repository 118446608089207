(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('authService', authService);

    authService.$inject = ['$q', '$http', '$httpParamSerializer', '$localForage', 'serviceUrls'];

    function authService($q, $http, $httpParamSerializer, $localForage, serviceUrls) {
        var authServiceVersion = '1.0.3';
        var authData = {
            customerId: '',
            initialized: false,
            isAuth: false,
            token: '',
            username: '',
            version: authServiceVersion
        };

        var authService = {
            login: login,
            logout: logout,
            getAuthData: getAuthData,
            isValidToken: isValidToken,
            isUpToDate: isUpToDate
        };

        return authService;

        function isUpToDate() {
            return authData.version === authServiceVersion;
        }

        function login(username, password) {
            var deferred = $q.defer();

            getToken(username, password).then(function (result) {
                authData.initialized = true;
                authData.isAuth = true;
                authData.customerId = result.customerId;
                authData.token = result.token;
                authData.username = username;
                authData.version = authServiceVersion;

                return $localForage.setItem('authData', authData);
            })
            .then(function () {
                deferred.resolve();
            })
            .catch(function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function logout() {
            return $http.post(serviceUrls.authorization + '/logout')
                .catch(() => { })
                .finally(clearAuthData);
        }

        function getAuthData() {
            var deferred = $q.defer();

            if (!authData || !authData.initialized) {
                $localForage.getItem('authData').then(function (storedAuthData) {
                    if (storedAuthData) {
                        authData.customerId = storedAuthData.customerId;
                        authData.initialized = true;
                        authData.isAuth = storedAuthData.isAuth;
                        authData.token = storedAuthData.token;
                        authData.username = storedAuthData.username;
                        authData.version = storedAuthData.version;
                    }
                    deferred.resolve(authData);
                }, function () {
                    $localForage.removeItem('authData').then(function () {
                        deferred.resolve(authData);
                    });
                });
            } else {
                deferred.resolve(authData);
            }

            return deferred.promise;
        }

        function isValidToken(token) {
            var deferred = $q.defer();

            $http.get(serviceUrls.authorization + '/' + token).then(function (result) {
                deferred.resolve(result.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function clearAuthData() {
            var deferred = $q.defer();

            $localForage.removeItem('authData').then(function () {
                authData.customerId = '';
                authData.isAuth = false;
                authData.token = '';
                authData.username = '';
                authData.version = '';

                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getToken(username, password) {
            var deferred = $q.defer();

            $http.post(serviceUrls.authorization, {
                username: username,
                password: password
            }).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
