(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('orderSortButton', orderSortButton);

    orderSortButton.$inject = ['containerOrdersService'];

    function orderSortButton(containerOrdersService) {
        return {
            replace: true,
            restrict: 'E',
            scope: {},
            template: `<i class="fa fa-2x order-sort-button" aria-hidden="true" ng-click="toggleSort()" 
                ng-class="isAscending ? 'fa-sort-amount-down' : 'fa-sort-amount-up'"></i>`,
            link,
        };

        function link(scope) {
            scope.toggleSort = toggleSort;
            scope.isAscending = containerOrdersService.isOrderSortAscending();

            function toggleSort() {
                scope.isAscending = !scope.isAscending;
                containerOrdersService.setOrderSortAscending(scope.isAscending);
            }
        }
    }
})();
