(function() {
    angular
        .module("PWAPoCApp")
        .controller("WeightSensorModalController", weightSensorModalController);

    weightSensorModalController.$inject = [
        "$timeout",
        "$rootScope",
        "$scope",
        "$windowInstance",
        "routeStop",
        "botekSensorListener",
        "appSettings",
        "dataButton"
    ];

    function weightSensorModalController(
        $timeout,
        $rootScope,
        $scope,
        $windowInstance,
        routeStop,
        botekSensorListener,
        appSettings,
        dataButton
    ) {
        var waitingMsg = "Venter på vektdata";
        $scope.isManualMode = false;
        $scope.numbers = "";
        $scope.WeightValues = [];

        initController();

        function initController() {
            $rootScope.$broadcast("showBusyIndicator", waitingMsg);
            if (!$rootScope.isWeightSensorPollingCache) {
                startTryGetSensorValues();
                $rootScope.isWeightSensorPollingCache = true;
            }
        }

        $scope.setValue = function() {
            botekSensorListener.stopListening();
            $windowInstance.close({
                routeStop: routeStop,
                weight: $scope.sumWeight
            });
        };

        $scope.setManual = function(sensor) {
            if (!Number($scope.sumWeight)) {
                $rootScope.$broadcast("hideBusyIndicator");
                initManual();
                $scope.isManualMode = true;
            }
        };

        $scope.OkButtonEnabled = function() {
            return Number($scope.sumWeight);
        };
        $scope.ManualEnabled = () => {
            return !Number($scope.sumWeight);
        };

        function startTryGetSensorValues() {
            botekSensorListener.getSensorValues().then(weightValues => {
                if (weightValues && weightValues.length) {
                    $rootScope.$broadcast("hideBusyIndicator");
                    $scope.waitingMsg = null;
                    $scope.sumWeight = _.sum(
                        _.map(weightValues, w => Number(w))
                    ).toString();
                    $scope.WeightValues = weightValues;
                } else {
                    $scope.waitingMsg = waitingMsg;
                }
                if ($rootScope.isWeightSensorPollingCache) {
                    setTimeout(
                        startTryGetSensorValues,
                        appSettings.botekCheckInterval
                    );
                }
            });
        }

        //manual input weight
        $scope.numbers = "";
        $scope.addComma = addComma;
        $scope.back = back;
        $scope.save = save;
        $scope.saveEnabled = () => Number($scope.sumWeight);

        function correctFloatFormat(data) {
            var dataValue = data;
            if (dataValue.indexOf(",") === 0) {
                dataValue = "0" + dataValue;
            }

            if (dataValue.indexOf(",") === dataValue.length - 1) {
                dataValue = dataValue.substring(0, dataValue.length - 1);
            }
            return dataValue;
        }

        function initManual() {
            if (dataButton.value) {
                $scope.numbers = dataButton.value.toString();
            }
        }

        function addComma() {
            if ($scope.numbers.indexOf(",") === -1) {
                $scope.numbers += ",";
            }
        }

        function back() {
            $scope.isManualMode = false;
            $rootScope.$broadcast("showBusyIndicator", waitingMsg);
        }

        function save() {
            $scope.sumWeight = correctFloatFormat($scope.numbers);
            $scope.setValue();
        }
    }
})();
