(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('routeStopFilter', routeStopFilter);

    routeStopFilter.$inject = [];

    function routeStopFilter() {
        var directive = {
            controller: ['$rootScope', '$scope', 'routeStopsService', 'appSettings', routeStopFilter],
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: 'app/shared/directives/route-stop-filter.html'
        };

        return directive;

        function routeStopFilter($rootScope, $scope, routeStopsService, appSettings) {
            $scope.filter = { option: null, value: '' };
            $scope.filterOptions = [];
            $scope.forceFiltering = false;

            $scope.filterOptionClicked = filterOptionClicked;
            $scope.filterChanged = filterChanged;
            $scope.clearFilter = clearFilter;

            $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                if (fromState.name === 'main.routeStops' || fromState.name === 'main.completedRouteStops') {
                    clearFilter();
                }
            });

            $scope.isActive = isActive;

            initializeFiltering();

            function initializeFiltering() {

                //configure selective filter usage for views
                $scope.filterOptions = _.reject(appSettings.filterOptions.slice(),
                    function(o) {
                        return _.indexOf(o.supportedViews, $rootScope.userType) === -1;
                    });
              
                var filterBy = routeStopsService.getFilterBy();
                $scope.filter.option = _.find($scope.filterOptions, { property: filterBy });
            }

            function filterOptionClicked(filterOption) {
                if (!isActive(filterOption)) {
                    $scope.filter.value = '';
                }
                $scope.filter.option = filterOption;
                routeStopsService.saveFilterBy(filterOption.property);
                filterChanged();
            }

            function filterChanged() {
                if ($scope.filter && $scope.filter.option) {
                    if ($scope.forceFiltering || $scope.filter.value.length >= $scope.filter.option.applyMinLength) {
                        $scope.forceFiltering = $scope.filter.value.length > 0;
                        $rootScope.$broadcast('routeStopsFilterChanged', $scope.filter);
                    }
                }
            }

            function clearFilter() {
                $scope.filter.value = '';
                filterChanged();
            }

            function isActive(filterOption) {
                if ($scope.filter && $scope.filter.option) {
                    return $scope.filter.option.id === filterOption.id;
                }
                return false;
            }

        }
    }
})();
