(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('uiExtensions', uiExtensions);

    uiExtensions.$inject = ['$q', '$http', '$rootScope', '$state'];

    function uiExtensions($q, $http, $rootScope, $state) {

        var uiExtensions = {
            initMobileSplitViewStatus: initMobileSplitViewStatus,
            calculateSpinnerPosition: calculateSpinnerPosition,
            isiOSDevice: isiOSDevice,
            stopEventPropagation: stopEventPropagation
        }

        return uiExtensions;

        function stopEventPropagation(evt) {
            if (evt) {
                evt.stopPropagation();
                evt.preventDefault();
            }
        }

        function initMobileSplitViewStatus() {
            if (!$rootScope.isMobilePortraitListInitialized) {
                var routeStopsPage = $('.route-stops.page.ng-scope');
                if (routeStopsPage.length > 0) {
                    if (routeStopsPage[0].clientWidth < 480) {
                        $rootScope.$broadcast('splitViewRequest', 'list');
                    }
                }
                $rootScope.isMobilePortraitListInitialized = true;
            }
        }

        function calculateSpinnerPosition() {
            var routeStopsPage = $('.route-stops.page')[0];
            var sLeft = 0;
            var sTop = 0;
            switch ($state.params.splitView) {
                case 'horizontal': {
                    sLeft = routeStopsPage.clientLeft + (routeStopsPage.clientWidth - 40) / 4;
                    sTop = routeStopsPage.clientTop + screen.availHeight / 2;
                    break;
                }
                case 'vertical': {
                    sLeft = routeStopsPage.clientLeft + routeStopsPage.clientWidth / 2;
                    sTop = (screen.availHeight - 40) / 4;
                    break;
                }
                case 'map': {
                    sLeft = routeStopsPage.clientLeft + 40 + routeStopsPage.clientWidth / 2;
                    sTop = routeStopsPage.clientTop + screen.availHeight / 2;
                    break;
                }
                default: {
                    sLeft = routeStopsPage.clientLeft + routeStopsPage.clientWidth / 2;
                    sTop = routeStopsPage.clientTop + screen.availHeight / 2;
                }
            }

            return { spinnerLeft: sLeft, spinnerTop: sTop };
        }

        function isiOSDevice() {
            var platform = navigator.platform;
            return platform === 'iPad' || platform === 'iPhone' || platform === 'iPod' || platform.includes('Mac');
        }
    }
})();
