(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('completedRouteStopRow', completedRouteStopRow);

    completedRouteStopRow.$inject = ['$rootScope', 'settingsService'];

    function completedRouteStopRow($rootScope, settingsService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/completed-route-stop-row.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {

            
        }
    }
})();
