(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('routeStopRowAjour', routeStopRowAjour);

    routeStopRowAjour.$inject = ['orderItemTypeService'];

    function routeStopRowAjour(orderItemTypeService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/route-stop-row-ajour.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.defaultIfEmpty = function(data){
                return data ? data : "-";
            };

            scope.getRouteStopIcon = function (routeStop) {
                return scope.getIcon(orderItemTypeService.getOrderItemType(routeStop));
            };

            scope.getIcon = function (orderType) {
                return orderItemTypeService.getIcon(orderType);
            };

            scope.splitSource = function (sourceItems, index) {
                var endInd = sourceItems.length / 2;
                var part = index === 0 ? sourceItems.slice(0, endInd) : sourceItems.slice(endInd, sourceItems.length);
                return part;
            };

            scope.displayFilter = function (sourceItems) {
                return _.reject(sourceItems, s => s.key === "DetailData_Address" || s.key === "DetailData_Message");
            };

            scope.filterForMessage = function (orderData) {
                return _.filter(orderData, { key: 'DetailData_Message' });
            };
        }
    }
})();
