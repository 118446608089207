(function() {
    angular
        .module("PWAPoCApp")
        .controller(
            "containerEventsModalController",
            ContainerEventsModalController
        );

    ContainerEventsModalController.$inject = [
        "$scope",
        "units",
        "containersService",
        "$windowInstance"
    ];

    function ContainerEventsModalController(
        $scope,
        units,
        containersService,
        $windowInstance
    ) {
        var today = new Date();

        $scope.deviceLogs = [];
        $scope.containerIds = [];
        $scope.dateFromObject = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 14
        );
        $scope.dateToObject = today;

        $scope.applyDateFilter = applyDateFilter;

        initController();

        function initController() {
            $scope.logfilter = units.map(unit => {
                return {
                    externalDeviceId: unit.containerId,
                    from: $scope.dateFromObject,
                    to: $scope.dateToObject
                };
            });

            containersService
                .getLogsByExtDeviceIdAndSystemBulk($scope.logfilter)
                .then(response => {
                    $scope.deviceLogs = response.data;
                    $scope.containerEventsGridOptions = {
                        dataSource: new kendo.data.DataSource({
                            transport: {
                                read: function(options) {
                                    if ($scope.deviceLogs) {
                                        options.success($scope.deviceLogs);
                                    } else {
                                        options.error();
                                    }
                                }
                            }
                        }),
                        sortable: true,
                        filterable: true,
                        noRecords: true,
                        messages: {
                            noRecords: "Ingen treff"
                        },
                        columns: [
                            {
                                field: "time",
                                title: "Dato/tid",
                                template:
                                    '#= kendo.toString(kendo.parseDate(time), "dd.MM.yyyy hh:mm:ss") #',
                                filterable: false
                            },
                            {
                                field: "logAction",
                                title: "Hendelse"
                            },
                            {
                                field: "comment",
                                title: "Kommentar"
                            },
                            {
                                field: "extUser",
                                title: "Bil"
                            }
                        ]
                    };
                })
                .catch(err => {
                    console.error(err);
                });
        }

        function applyDateFilter() {
            $scope.logfilter.forEach(filter => {
                filter.from = $scope.dateFromObject;
                filter.to = $scope.dateToObject;
            });
            containersService
                .getLogsByExtDeviceIdAndSystemBulk($scope.logfilter)
                .then(response => {
                    $scope.deviceLogs = response.data;
                    $scope.containerEventsGridOptions.dataSource.read();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }
})();
