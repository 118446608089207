(function () {
    'use strict';
    const orderDateFilterValueCacheKey = "ORDER_DATE_FILTER_VALUE_CACHE_KEY";
    const orderSortAscendingCacheKey = "ORDER_SORT_ASCENDING_CACHE_KEY";
    angular.module('PWAPoCApp').factory('containerOrdersService', containerOrdersService);
    containerOrdersService.$inject = [
        '$rootScope',
        '$q',
        '$http',
        '$interval',
        'RouteStop',
        'locationService',
        'cacheService',
        'serviceUrls',
        'appSettings',
        'authService',
        'appVersion',
        'orderTypeService',
        'orderDateFilterOptions',
    ];
    function containerOrdersService($rootScope, $q, $http, $interval, RouteStop, locationService, cacheService, serviceUrls, appSettings, authService, appVersion, orderTypeService, orderDateFilterOptions) {
        const routeStopsCacheKey = '_routeStops_';
        const cachePrefix = '_orders_';
        let timer = null, isPollRunning = false;
        let inProgressGetContainerOrdersRequest = null;
        let orderDateFilterValue = orderDateFilterOptions[0].value;
        let sortOrdersAscending = false;
        let containerOrdersService = {
            getContainerOrders,
            unAssignOrderFromCurrentUser,
            assignOrderToCurrentUser,
            startPolling,
            stopPolling,
            getChanges,
            getOrderDateFilter,
            getOrderDateFilterFunction,
            setOrderDateFilter,
            isOrderSortAscending,
            setOrderSortAscending,
        };
        initService();
        return containerOrdersService;
        function initService() {
            cacheService.get(orderDateFilterValueCacheKey).then((value) => {
                orderDateFilterValue = value || orderDateFilterValue;
            });
            cacheService.get(orderSortAscendingCacheKey).then((value) => {
                sortOrdersAscending = !!value;
            });
        }
        function startPolling() {
            if (timer === null) {
                isPollRunning = false;
                timer = $interval(() => {
                    if (!isPollRunning) {
                        isPollRunning = true;
                        getContainerOrderChanges().finally(() => (isPollRunning = false));
                    }
                }, appSettings.checkOrderChangesIntervalMs);
            }
        }
        function stopPolling() {
            $interval.cancel(timer);
            isPollRunning = false;
            timer = null;
        }
        //Public interface
        function getContainerOrders(refreshCache) {
            var deferred = $q.defer();
            if (!refreshCache) {
                cacheService
                    .has(cachePrefix)
                    .then(function (exists) {
                    if (exists) {
                        return cacheService.get(cachePrefix);
                    }
                    else {
                        return getServerOrders();
                    }
                })
                    .then(function (orders) {
                    deferred.resolve(orders);
                })
                    .catch(function () {
                    deferred.reject();
                });
            }
            else {
                //refresh cache
                getServerOrders().then(function (orders) {
                    deferred.resolve(orders);
                }, function () {
                    cacheService
                        .has(cachePrefix)
                        .then(function (exists) {
                        if (exists) {
                            return cacheService.get(cachePrefix);
                        }
                        else {
                            deferred.reject();
                        }
                    })
                        .then(function (orders) {
                        orders = _.filter(orders, { isRouteCached: true });
                        if (orders && orders.length)
                            deferred.resolve(orders);
                        else
                            deferred.reject();
                    })
                        .catch(function () {
                        deferred.reject();
                    });
                });
            }
            return deferred.promise;
        }
        function getContainerOrderChanges() {
            let deferred = $q.defer();
            if ($rootScope.isOnline) {
                var cachedOrders;
                getContainerOrders(false)
                    .then((orders) => {
                    cachedOrders = orders;
                    return $http.get(serviceUrls.containerOrders + '/changes');
                })
                    .then((resp) => {
                    if (resp && resp.data) {
                        let generalResponse = resp.data;
                        if (generalResponse.status === 200) {
                            let assignDtos = generalResponse.response;
                            cacheService.get('assignStates', assignDtos).then((assignStates) => {
                                let localStates = assignStates ? assignStates : [];
                                let changes = getChanges(localStates, assignDtos);
                                cacheService.set('assignStates', assignDtos).then(() => {
                                    if (changes.gotUnassigned.length) {
                                        $rootScope.$broadcast('refreshOrders');
                                    }
                                    else {
                                        if (changes.gotAssigned.length > 0) {
                                            $rootScope.$broadcast('orderAssigned', changes.gotAssigned);
                                        }
                                    }
                                });
                                deferred.resolve();
                            });
                        }
                        else {
                            deferred.reject();
                        }
                    }
                })
                    .catch(() => {
                    deferred.reject();
                });
            }
            else {
                deferred.reject();
            }
            return deferred.promise;
        }
        function getChanges(previousDtos, assignDtos) {
            let remainedSame = _.filter(previousDtos, (co) => _.find(assignDtos, (dto) => dto.orderId === co.orderId && co.username === dto.username));
            //filter for the orders that are cached but not in the list of incoming data
            let gotAssigned = _.filter(previousDtos, (co) => !_.find(assignDtos, (dto) => dto.orderId === co.orderId));
            //reject from incoming list the ones that are cached
            let previousAssigns = _.filter(previousDtos, (co) => co.username === 'other');
            let changedUnassignedOrders = _.filter(previousAssigns, (pa) => _.some(assignDtos, (ao) => ao.orderId === pa.orderId && !ao.username));
            let gotUnassigned = changedUnassignedOrders.concat(_.reject(assignDtos, (dto) => _.some(previousDtos, (co) => co.orderId === dto.orderId)));
            return {
                remainedSame,
                gotAssigned,
                gotUnassigned,
            };
        }
        function assignOrderToCurrentUser(orderId, orderType) {
            return sendAssignRequest(orderId, orderType, true);
        }
        function unAssignOrderFromCurrentUser(orderId, orderType) {
            return sendAssignRequest(orderId, orderType, false);
        }
        function sendAssignRequest(orderId, orderType, isAssign) {
            let deferred = $q.defer();
            if ($rootScope.isOnline) {
                let assignOrderDto = {
                    orderId: orderId,
                    isAssign: isAssign,
                    orderType: orderType,
                };
                $http
                    .put(serviceUrls.containerOrders, assignOrderDto)
                    .then((response) => {
                    if (response && response.data) {
                        let resp = response.data;
                        if (resp.status === 200) {
                            deferred.resolve();
                        }
                        else {
                            if (resp.message && resp.message.includes('invalid order status')) {
                                deferred.reject(resp.response);
                            }
                            deferred.reject(-1);
                        }
                    }
                })
                    .catch(() => {
                    deferred.reject();
                });
            }
            else {
                deferred.reject();
            }
            return deferred.promise;
        }
        function getOrderDateFilter() {
            return orderDateFilterValue;
        }
        function getOrderDateFilterFunction() {
            switch (orderDateFilterValue) {
                case 'thisWeek':
                    return (order) => moment(order.expiresDate).isBetween(moment().subtract(1, 'week'), undefined);
                case 'thisMonth':
                    return (order) => moment(order.expiresDate).isBetween(moment().subtract(1, 'month'), undefined);
                case 'all':
                default:
                    return () => true;
            }
        }
        function setOrderDateFilter(filterValue) {
            orderDateFilterValue = filterValue;
            $rootScope.$broadcast('orderDateFilterChanged');
            return cacheService.set(orderDateFilterValueCacheKey, filterValue);
        }
        // private functions
        function getServerOrders() {
            if (inProgressGetContainerOrdersRequest) {
                return inProgressGetContainerOrdersRequest;
            }
            let deferred = $q.defer();
            let orders = [];
            let position;
            let authData;
            let serverOrders = [];
            let cachedOrders = [];
            let allOrders = [];
            inProgressGetContainerOrdersRequest = deferred.promise;
            inProgressGetContainerOrdersRequest.finally(() => {
                inProgressGetContainerOrdersRequest = null;
            });
            cacheService
                .has(cachePrefix)
                .then(function (exists) {
                if (exists) {
                    return cacheService.get(cachePrefix);
                }
                else {
                    return $q.when();
                }
            })
                .then((ordersInCache) => {
                if (ordersInCache) {
                    cachedOrders = _.filter(ordersInCache, { isRouteCached: true });
                }
                return locationService.getCurrentPosition();
            })
                .then(function (currentPosition) {
                position = currentPosition;
                return authService.getAuthData();
            })
                .then(function (currentAuthData) {
                authData = currentAuthData;
                return $q.when();
            })
                .then(() => {
                return $http.get(serviceUrls.containerOrders);
            })
                .then(function (response) {
                serverOrders = _.reject(response.data, (sOrder) => _.some(cachedOrders, { orderId: sOrder.order.orderId }));
                if (serverOrders && serverOrders.length) {
                    _.forEach(serverOrders, function (serverOrder, index) {
                        let order = serverOrder.order;
                        let units = _.flatten(_.map(serverOrder.routelines, 'units'));
                        let unitAddress = units && units.length ? _.map(units, 'address') : '';
                        let unitName = units && units.length ? _.map(units, 'name') : '';
                        let orderDate = new Date(order.orderDate);
                        let expiresDetail = _.find(serverOrder.orderData, { key: 'DetailData_Expires' });
                        order.date =
                            orderDate.getDate().toString().padStart(2, '0') + '/' + (orderDate.getMonth() + 1);
                        order.isRouteCached = false;
                        order.transitionId = null;
                        order.orderId = order.orderId;
                        order.unitAddress = unitAddress ? unitAddress.join(', ') : '';
                        order.unitName = unitName ? unitName.join(', ') : '';
                        order.orderTypeName = orderTypeService.getOrderTypeName(order.orderType);
                        order.expires = expiresDetail ? expiresDetail.value : '';
                        order.expiresDate = expiresDetail ? moment(expiresDetail.value, 'DD.MM.YYYY').format('YYYY-MM-DD') : '';
                        order.expiresDescription = expiresDetail ? expiresDetail.description : '';
                        order.extOrderId = getExternalOrderId(serverOrder);
                        order.orderInfoDetailData = serverOrder.orderInfoDetailData;
                        orders.push(order);
                    });
                    allOrders = cachedOrders.concat(orders);
                    return cacheService.set(cachePrefix, allOrders);
                }
                allOrders = cachedOrders.concat(orders);
            })
                .then(() => {
                let saveRouteStops = [];
                _.forEach(serverOrders, function (serverContainerOrder) {
                    serverContainerOrder.transitionId = null;
                    let route = {
                        name: serverContainerOrder.order.routeName || serverContainerOrder.order.route,
                        routeStops: [],
                    };
                    route.routeStops = _.map(serverContainerOrder.routelines, function (routeline) {
                        let routeStop = new RouteStop(routeline, position, $rootScope.userSettings.dataButtons);
                        routeStop.customerId = authData.customerId;
                        routeStop.appVersion = appVersion;
                        routeStop.callOrderId = serverContainerOrder.order.orderId;
                        routeStop.orderInfoDetailData = _.map(serverContainerOrder.orderInfoDetailData, function (info) {
                            replaceInfoDescription(info, 'DetailData_Message', 'Kommentar');
                            replaceInfoDescription(info, 'AgreementLineDetailData_Message', 'Plassering');
                            return info;
                        });
                        return routeStop;
                    });
                    saveRouteStops.push(cacheService.set(routeStopsCacheKey + serverContainerOrder.order.orderId, route));
                });
                return $q.all(saveRouteStops);
            })
                .then(() => deferred.resolve(allOrders))
                .catch(() => deferred.reject());
            return deferred.promise;
        }
        function replaceInfoDescription(info, keyName, newDescription) {
            if (info.key === keyName) {
                let newInfo = Object.assign({}, info);
                newInfo.description = newDescription;
                return newInfo;
            }
        }
        function isOrderSortAscending() {
            return sortOrdersAscending;
        }
        function setOrderSortAscending(sortAscending) {
            if (sortOrdersAscending === sortAscending)
                return;
            sortOrdersAscending = sortAscending;
            $rootScope.$broadcast('orderDateFilterChanged', sortOrdersAscending);
            return cacheService.set(orderSortAscendingCacheKey, sortOrdersAscending);
        }
        function getExternalOrderId(serverOrder) {
            let externalOrderId = '';
            if (
                serverOrder &&
                serverOrder.agreementDetailData &&
                serverOrder.agreementDetailData.agreementlines &&
                serverOrder.agreementDetailData.agreementlines.length > 0
            ) {
                serverOrder.agreementDetailData.agreementlines.forEach((agrLine) => {
                    if (agrLine.extOrderId) {
                        externalOrderId = agrLine.extOrderId;
                    }
                });
            }
            return externalOrderId;
        }
    }
})();
