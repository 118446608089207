(function () {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("orderConfirmModalController", orderConfirmModalController);

    orderConfirmModalController.$inject = [
        "$q",
        "$rootScope",
        "$scope",
        "$state",
        "$windowInstance",
        "routeStop"
    ];

    function orderConfirmModalController(
        $q,
        $rootScope,
        $scope,
        $state,
        $windowInstance,
        routeStop
    ) {
        initController();
        function initController() {
            var p = $windowInstance;
            p.rendered.then(()=>$('.k-window-content.k-content').css('background-color', '#cbdae7'));
            $scope.routeStop = routeStop;
            $scope.showArticles = routeStop.articles && routeStop.articles.length;
            $scope.showDeviations = routeStop.deviations && routeStop.deviations.length;
            $scope.showComment = routeStop.comments && routeStop.comments.length;
            $scope.showPictures = routeStop.deviationPictures && routeStop.deviationPictures.length;

            $scope.defaultIfEmpty = function (value) {
                if (!value) {
                    return "0";
                }
                return value;
            }

            $scope.stopError = function () {
                $windowInstance.close(false);
            }

            $scope.stopApproved = function () {
                $windowInstance.close(true);
            }
        }
    }
})();
