(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('errorReportService', errorReportService);

    errorReportService.$inject = [
        '$q',
        '$http',
        'serviceUrls',
        'cacheService',
        'logService'
    ];

    function errorReportService(
        $q,
        $http,
        serviceUrls,
        cacheService,
        logService
    ) {
        let errorReportService = {
            sendErrorReport: sendErrorReport
        };

        return errorReportService;

        function sendErrorReport(message, isSystem) {
            let deferred = $q.defer();

            cacheService.getAllKeysWithoutUserPrefix().then(function (items) {
                var errorReportLines = _.map(items, function (item) {
                    return {
                        content: JSON.stringify(item.value.data),
                        key: item.key,
                        type: 'cache'
                    };
                });

                var errorReport = {
                    content: message,
                    errorReportLines: errorReportLines,
                    isSystem: isSystem
                };

                return $http.post(serviceUrls.errorReport, errorReport);
            })
            .then(function (resp) {
                if (resp && resp.data && resp.data.status === 200){
                    setTimeout(()=>logService.deleteLogs(), 10);
                }
                cacheService.remove('_trace');
                deferred.resolve();
            })
            .catch(function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
