(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("commentModalController", commentModalController);

    commentModalController.$inject = [
        "$scope",
        "$windowInstance",
        "routeStop",
        "selectedAgreementLines"
    ];

    function commentModalController(
        $scope,
        $windowInstance,
        routeStop,
        selectedAgreementLines
    ) {
        $scope.comment = "";
        
        $scope.setPreviousComment = function() {
            if (routeStop.comments.length > 0) {
                var comments = [];

                _.forEach(selectedAgreementLines, function(agreementLine) {
                    var comment = _.find(routeStop.comments, function(c) {
                        return c.agreementLineId === agreementLine;
                    });

                    if (comment) {
                        comments.push(comment);
                    }
                });

                if (_.uniqBy(comments, "comment").length === 1) {
                    $scope.comment = comments[0].comment;
                }
            }
        };

        $scope.addComment = function() {
            _.forEach(selectedAgreementLines, function(agreementLine) {
                _.remove(routeStop.comments, function(c) {
                    return c.agreementLineId === agreementLine;
                });
            });
            if ($scope.comment) {
                _.forEach(selectedAgreementLines, function(agreementLine) {
                    routeStop.comments.push({
                        agreementLineId: agreementLine,
                        comment: $scope.comment
                    });
                });
            }
            $windowInstance.close();
        };

        $scope.close = function() {
            $windowInstance.close();
        };

        function initController() {
            $scope.setPreviousComment();
        }

        initController();
    }
})();
