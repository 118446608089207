(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('googleDirectionsService', googleDirectionsService);

    googleDirectionsService.$inject = ['$q', '$log', '$http'];

    function googleDirectionsService($q, $log, $http) {

        var service = {
            getOptimizedRouteOrder: getOptimizedRouteOrder
        };

        return service;
        
        function getOptimizedRouteOrder(origin, destination, waypoints) {
            var deferred = $q.defer();

            $http.post('api/routeStops/optimizeRoute', { origin, destination, waypoints })
                .then((response) => {
                    if (response && response.status === 200) {
                        var apiResponse = response.data;
                        if (apiResponse && apiResponse.status === "OK" && apiResponse.routes && apiResponse.routes.length && apiResponse.routes[0].waypointOrder) {
                            deferred.resolve(apiResponse.routes[0].waypointOrder);
                        } else {
                            $log.error("bad api response: status:" + apiResponse.status + " error:" + apiResponse.errorMessage);
                            deferred.resolve(null);
                        }
                    } else {
                        deferred.resolve(null);
                    }
                })
                .catch((error) => {
                    $log.error("request failed: googleDirectionsService");
                    deferred.resolve(null);
                });

            return deferred.promise;
        }
    }
})();
