(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller(
            "OrderDetailTypesFilterController",
            orderDetailTypesFilterController
        );

    orderDetailTypesFilterController.$inject = [
        "$rootScope",
        "$scope",
        "$windowInstance",
        "orderDetailTypesService"
    ];

    function orderDetailTypesFilterController(
        $rootScope,
        $scope,
        $windowInstance,
        orderDetailTypesService
    ) {
        $scope.selectedOrderDetailTypes = [];

        $scope.close = close;
        $scope.isOrderDetailTypeSelected = isOrderDetailTypeSelected;
        $scope.ok = ok;
        $scope.toggleOrderDetailType = toggleOrderDetailType;

        initController();

        function initController() {
            orderDetailTypesService
                .getSelectedOrderDetailTypes()
                .then(function(selectedOrderDetailTypes) {
                    $scope.selectedOrderDetailTypes =
                        selectedOrderDetailTypes || [];
                });
        }

        function close() {
            $windowInstance.close();
        }

        function isOrderDetailTypeSelected(orderDetailType) {
            return _.includes(
                $scope.selectedOrderDetailTypes,
                orderDetailType.orderDetailTypeId
            );
        }

        function ok() {
            orderDetailTypesService
                .setSelectedOrderDetailTypes($scope.selectedOrderDetailTypes)
                .then(function() {
                    $rootScope.$broadcast(
                        "orderDetailTypesFilterChanged",
                        $scope.selectedOrderDetailTypes
                    );
                    $windowInstance.close();
                });
        }

        function toggleOrderDetailType(orderDetailType) {
            if (isOrderDetailTypeSelected(orderDetailType)) {
                _.pull(
                    $scope.selectedOrderDetailTypes,
                    orderDetailType.orderDetailTypeId
                );
            } else {
                $scope.selectedOrderDetailTypes.push(
                    orderDetailType.orderDetailTypeId
                );
            }
        }
    }
})();
