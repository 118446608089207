(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .factory("botekSensorListener", botekSensorListener);

    botekSensorListener.$inject = [
        "$q",
        "$rootScope",
        "$timeout",
        "$http",
        "$log",
        "$httpParamSerializer",
        "$localForage",
        "bluetoothService",
        "appSettings",
        "cacheService"
    ];

    function botekSensorListener(
        $q,
        $rootScope,
        $timeout,
        $http,
        $log,
        $httpParamSerializer,
        $localForage,
        bluetoothService,
        appSettings,
        cacheService
    ) {
        var botekCacheKey = "Botek_Values";
        var notificationsEnabled = false;
        var msgBuffer = [];
        var isListening = false;
        var botekSensorListener = {
            startListening: startListening,
            stopListening: stopListening,
            getSensorValues: getSensorValues
        };

        return botekSensorListener;

        function startListening() {
            cacheService.set(botekCacheKey, []).then(() => {
                if (isListening !== true) {
                    bluetoothService.startNotifications(handleNotifications);
                    isListening = true;
                }
            });
        }

        function getSensorValues() {
            return cacheService.get(botekCacheKey);
        }

        function addSensorValue(value) {
            cacheService.addTo(botekCacheKey, value, false);
        }

        function formatWeightString(weightString) {
            var signedNumPart = weightString.split(" ")[0];
            var numPart = signedNumPart.substring(1, signedNumPart.length);
            if (!numPart.includes(",")) {
                let leadingZeroCound = 0;
                for (let i = 0; i < numPart.length; i++) {
                    if (numPart[i] != "0") {
                        break;
                    } else {
                        leadingZeroCound++;
                    }
                }
                return numPart.substring(leadingZeroCound, numPart.length);
            }
        }

        function isMsgTerminated(buffer, c) {
            return (
                c === 0x0d &&
                buffer.length > 0 &&
                buffer[buffer.length - 1] === 0x0a
            );
        }

        function handleNotifications(event) {
            let value = event.target.value;

            for (let i = 0; i < value.byteLength; i++) {
                let c = value.getUint8(i);
                if (isMsgTerminated(msgBuffer, c)) {
                    var weightString = _.map(msgBuffer, b =>
                        String.fromCharCode(b)
                    ).join("");
                    var weightValue = formatWeightString(weightString);
                    addSensorValue(weightValue);
                    msgBuffer = [];
                } else {
                    msgBuffer.push(c);
                }
            }
        }

        function stopListening() {
            cacheService.set(botekCacheKey, []).then(() => {
                if (isListening !== false) {
                    bluetoothService.stopNotifications(handleNotifications);
                    isListening = false;
                }
            });
        }
    }
})();
