(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('commonUtil', commonUtil);

    commonUtil.$inject = ['orderDataDescriptions'];

    function commonUtil(orderDataDescriptions) {
        var service = {
            generateGuid: generateGuid,
            mapDetailDataKey: mapDetailDataKey,
            getOrderDataItemSanitizedValue: getOrderDataItemSanitizedValue,
            queryBuilder: queryBuilder
        };

        return service;

        function generateGuid() {
            var d = new Date().getTime();
            if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
                d += performance.now();
            }
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }

        function mapDetailDataKey(value) {
            var result = value;

            if (value in orderDataDescriptions) {
                result = orderDataDescriptions[value];
            } else {
                var regex = /\[~(.*?)~\]/i;
                var matches = value.match(regex);

                if (matches && matches.length > 1 && orderDataDescriptions[matches[1]]) {
                    result = orderDataDescriptions[matches[1]];
                }
            }

            return result;
        }

        function getOrderDataItemSanitizedValue(orderDataItem) {
            if (!orderDataItem || orderDataItem.value == undefined) {
                return '';
            }

            if (orderDataItem.key === '[agreementLine_LastDate]' || orderDataItem.key === '[agreementLine_NextDate]') {
                if (orderDataItem.value === '01/01/1900 00:00:00') {
                    return '';
                }

                var time = moment(orderDataItem.value, 'MM/DD/YYYY hh:mm:ss').format('DD/MM/YYYY');
                return time;
            } else {
                if (orderDataItem.value.toLowerCase() === 'true') {
                    return 'Ja';
                }
                else if (orderDataItem.value.toLowerCase() === 'false') {
                    return 'Nei';
                }
                return orderDataItem.value;
            }
        }

        function queryBuilder() {
            var self = this;
            self.url = "";

            self.startUrl = function (startUrl) {
                self.url = startUrl;
                return self;
            };

            self.addParam = function (paramName, paramValue) {
                self.url += "&" + paramName + "=" + paramValue;
                return self;
            };

            return self;
        }
    }
})();
