(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("RouteStopInfoController", routeStopInfoController);

    routeStopInfoController.$inject = [
        "$timeout",
        "$scope",
        "$rootScope",
        "$state",
        "$windowInstance",
        "commonUtil",
        "locationService",
        "routeStopsService",
        "appSettings",
        "orderData",
        "routeStop"
    ];

    function routeStopInfoController(
        $timeout,
        $scope,
        $rootScope,
        $state,
        $windowInstance,
        commonUtil,
        locationService,
        routeStopsService,
        appSettings,
        orderData,
        routeStop
    ) {
        const longitude = routeStop.longitude;
        const latitude = routeStop.latitude;
        var gpsAccuracy;
        $scope.longitude = createCoordText(longitude);
        $scope.latitude = createCoordText(latitude);
        $scope.adjustPositionAccuracyLimit =
            appSettings.routeStops.adjustPositionAccuracyLimit;
        $scope.orderId = routeStop.orderId || $state.params.orderId;
        $scope.routeStop = routeStop;

        $scope.map = map;
        $scope.orderData = orderData;
        $scope.ok = ok;
        $scope.isAccuracyGood = isAccuracyGood;
        $scope.validateCoordinates = validateCoordinates;
        $scope.update = update;
        $scope.isLoading = false;
        $scope.updateUnit = updateUnit;
        $scope.hasLongInfoNames = hasLongInfoNames;
        $scope.transformValue = transformValue;
        locationService.getCurrentPosition().then(function(position) {
            gpsAccuracy = position.coords.accuracy;
            $scope.accuracy = parseFloat(
                position.coords.accuracy
            ).toLocaleString("no-NO", { maximumFractionDigits: 6 });
            $scope.deviceLongitude = position.coords.longitude;
            $scope.deviceLatitude = position.coords.latitude;
        });

        $scope.isAjour = $rootScope.userType === "ajour";

        $rootScope.$on("locationChanged", updateAccuracy);

        $timeout(100).then(initModal);

        function initModal() {
            let modalWidth = $(".k-window").width();
            if (modalWidth) {
                $(".k-window .modal-general").width(modalWidth);
            }
        }

        function transformValue(item) {
            return commonUtil.getOrderDataItemSanitizedValue(item);
        }

        function hasLongInfoNames() {
            const maxShortDescription = 9;
            return (
                _.filter(
                    orderData,
                    orderDataItem =>
                        orderDataItem.description.length > maxShortDescription
                ).length > 0
            );
        }

        function updateUnit() {
            if (!isAccuracyGood()) {
                return;
            }
            showBusyIndicator();

            locationService.getCurrentPosition().then(function(position) {
                if (
                    !validateCoordinates(
                        position.coords.longitude,
                        position.coords.latitude
                    )
                ) {
                    return;
                }

                let unit = $scope.routeStop.units[0];
                let lat = position.coords.latitude;
                let lng = position.coords.longitude;
                unit.x = lng;
                unit.y = lat;
                if (
                    unit &&
                    unit.agreementLines &&
                    unit.agreementLines.length &&
                    unit.agreementLines[0]
                ) {
                    routeStopsService
                        .updateUnitCoordinates(
                            unit.agreementLines[0],
                            $scope.routeStop.system,
                            lng,
                            lat
                        )
                        .then(
                            function() {
                                $rootScope.$broadcast(
                                    "UnitLocationChanged",
                                    $scope.routeStop
                                );
                                hideBusyIndicator();
                            },
                            function(err) {
                                alert("Juster slamtank feil");
                                hideBusyIndicator();
                            }
                        );
                } else {
                    hideBusyIndicator();
                }
            });
        }

        function updateAccuracy(evt, position) {
            gpsAccuracy = position.coords.accuracy;
            $scope.accuracy = parseFloat(
                position.coords.accuracy
            ).toLocaleString("no-NO", { maximumFractionDigits: 6 });
            $scope.deviceLongitude = position.coords.longitude;
            $scope.deviceLatitude = position.coords.latitude;
        }

        function isAccuracyGood() {
            return Number(gpsAccuracy) <= $scope.adjustPositionAccuracyLimit;
        }

        function map(value) {
            var result = commonUtil.mapDetailDataKey(value);
            return result;
        }

        function ok() {
            if ($scope.isLoading) {
                hideBusyIndicator();
            }
            $windowInstance.close();
        }

        function update() {
            if (!isAccuracyGood()) {
                return;
            }
            showBusyIndicator();

            locationService.getCurrentPosition().then(function(position) {
                if (
                    !validateCoordinates(
                        position.coords.longitude,
                        position.coords.latitude
                    )
                ) {
                    return;
                }

                routeStop.longitude = position.coords.longitude;
                routeStop.latitude = position.coords.latitude;

                routeStopsService
                    .updateRouteStopCoords($scope.orderId, routeStop)
                    .then(
                        function() {
                            $scope.longitude = createCoordText(
                                routeStop.longitude
                            );
                            $scope.latitude = createCoordText(
                                routeStop.latitude
                            );
                            $rootScope.$broadcast(
                                "routeStopLocationChanged",
                                $scope.routeStop.routeLineId
                            ); //update map + recalculate route stop distances + reorder distances

                            hideBusyIndicator();
                        },
                        function(err) {
                            hideBusyIndicator();
                        }
                    );
            });
        }

        function showBusyIndicator() {
            $scope.isLoading = true;
            $rootScope.$broadcast("showBusyIndicator");
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast("hideBusyIndicator");
            $scope.isLoading = false;
        }

        function createCoordText(coord) {
            //intentionally changed to hungarian format (comma notation is needed for compatibility), no-NO causes it to format with decimal dot
            return coord === null || coord === undefined
                ? "N/A"
                : parseFloat(coord).toLocaleString("hu-HU", {
                      maximumFractionDigits: 6
                  });
        }

        function validateCoordinates(long, lat) {
            if (long == null || lat == null) {
                return false;
            }
            var correctLongitude = long >= -180 && long <= 180;
            var correctLatitude = lat > 0.1 && lat <= 90;
            return correctLongitude && correctLatitude;
        }
    }
})();
