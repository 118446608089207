(function() {
    'use strict';

    angular.module('PWAPoCApp').controller('DeviationModalController', deviationModalController);

    deviationModalController.$inject = [
        '$rootScope',
        '$scope',
        '$kWindow',
        '$windowInstance',
        '$timeout',
        'settingsService',
        'deviationService',
        'deviationList',
        'profileInfoService',
        'orderId',
        'routeStop',
        'isCallOrder',
        'setToNo'
    ];

    function deviationModalController(
        $rootScope,
        $scope,
        $kWindow,
        $windowInstance,
        $timeout,
        settingsService,
        deviationService,
        deviationList,
        profileInfoService,
        orderId,
        routeStop,
        isCallOrder,
        setToNo
    ) {
        $scope.routeStop = null;
        $scope.deviations = deviationList;

        $scope.deviationCategories = [];

        $scope.selectedAgreementLines = [];
        $scope.selectedCategories = [];
        $scope.selectedDeviations = [];
        $scope.comment = '';
        $scope.unitsWithPicture = [];
        $scope.profileInfoOfflineMsg = null;
        $scope.selectedAllCategory = false;
        $scope.profileInfos = [];

        $timeout(initController, 250);

        var isChildModalOpen = false;
        var enableOpenImageList = true;

        function initController() {
            $scope.callOrder = isCallOrder;
            $scope.orderId = orderId;
            $scope.routeStop = routeStop;

            $rootScope.$broadcast('onDeviationWindowOpened');

            $scope.selectedAgreementLines = _.map($scope.routeStop.units, function(u) {
                return u.agreementLines[0];
            });

            settingsService.getCategories().then(function(categories) {
                $scope.deviationCategories = categories;
            });

            deviationService.getImagesFromCache($scope.orderId, $scope.routeStop.routeLineId).then(function(images) {
                if (images) {
                    var unitImages = _.flatten(_.map(images, 'agreementLineIds'));
                    var agreementLines = _.uniq(unitImages);
                    var unitPicCounts = _.map(agreementLines, u => ({
                        agreementLineId: u,
                        picCount: _.filter(unitImages, img => img === u).length
                    }));
                    $scope.unitsWithPicture = agreementLines;
                    $scope.unitPicCounts = unitPicCounts;
                }
            });

            if (setToNo) {
                $scope.toggleAllState = 'Nei';
                for (var i = 0; i < $scope.routeStop.units.length; i++) {
                    $scope.routeStop.units[i].message = 'Nei';
                }
            }

            if ($rootScope.isOnline) {
                $scope.profileInfoOfflineMsg = '';
                var agreementLineDataList = _.map($scope.selectedAgreementLines, function(line) {
                    return {
                        agreementLineId: Number(line),
                        PASystem: $scope.routeStop.system
                    };
                });
                if ($rootScope.loadProfileInfoData) {
                    profileInfoService
                        .retrieveFullProfileInfo($scope.selectedAgreementLines, $scope.routeStop.system)
                        .then(function(resp) {
                            var i = 0;
                            if (agreementLineDataList.length === resp.agreementlines.length) {
                                _.forEach(_.map(agreementLineDataList, 'agreementLineId'), agreementLineId => {
                                    var places = resp.places.length && resp.places[i] ? resp.places[i].data : [];
                                    var agreementLines =
                                        resp.agreementlines.length &&
                                        resp.agreementlines[i] &&
                                        resp.agreementlines[i].data
                                            ? resp.agreementlines[i].data
                                            : [];
                                    var agreementsData = resp.agreements.length
                                        ? _.map(
                                              _.filter(resp.agreements, {
                                                  agreementId: resp.agreementlines[i].agreementId
                                              }),
                                              'data'
                                          )
                                        : [];
                                    var agreements = agreementsData && agreementsData.length ? agreementsData[0] : [];
                                    $scope.profileInfos[agreementLineId] = places
                                        .concat(agreements)
                                        .concat(agreementLines);
                                    i++;
                                });
                            }

                            $scope.profileInfoVisible =
                                $scope.profileInfoOfflineMsg ||
                                ($scope.loadProfileInfoData && $scope.profileInfos && $scope.profileInfos.length > 0);
                        });
                }
            } else {
                $scope.profileInfoOfflineMsg = 'Informasjon ikke tilgjengelig uten dekning.';
            }
        }

        $scope.getProfileInfos = function(unit) {
            if (
                unit &&
                unit.agreementLines &&
                unit.agreementLines.length &&
                $scope.profileInfos &&
                $scope.profileInfos.length
            ) {
                return $scope.profileInfos[unit.agreementLines[0]];
            }
            return [];
        };

        $scope.openImageList = function() {
            if (enableOpenImageList) {
                if (!isChildModalOpen) {
                    enableOpenImageList = false;
                    isChildModalOpen = true;
                    var imageWindow = $kWindow.open({
                        options: {
                            modal: true,
                            title: 'Bilde',
                            movable: false,
                            resizable: false,
                            visible: false,
                            height: 580,
                            width: 640
                        },
                        templateUrl: 'app/routeStops/modals/image-list-modal.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'ImageListModalController',
                        resolve: {
                            orderId: () => $scope.orderId,
                            routeStop: () => $scope.routeStop,
                            selectedAgreementLines: () => $scope.selectedAgreementLines,
                            enableOpenImageListCallback: () => {
                                return setEnableOpenImageList;
                            }
                        }
                    });
                    imageWindow.result.finally(() => {
                        //reload "has pictures" icons if any pictures were created or deleted
                        deviationService
                            .getImagesFromCache($scope.orderId, $scope.routeStop.routeLineId)
                            .then(function(images) {
                                if (images) {
                                    var unitImages = _.flatten(_.map(images, 'agreementLineIds'));
                                    var agreementLines = _.uniq(unitImages);
                                    var unitPicCounts = _.map(agreementLines, u => ({
                                        agreementLineId: u,
                                        picCount: _.filter(unitImages, img => img === u).length
                                    }));
                                    $scope.unitsWithPicture = agreementLines;
                                    $scope.unitPicCounts = unitPicCounts;
                                }
                            });
                        enableChildModalOpening();
                    });
                }
            }

            function setEnableOpenImageList() {
                enableOpenImageList = true;
            }

            function preventPropagation(evt) {
                evt.stopPropagation();
                evt.preventDefault();
            }
        };

        function getMessageParts(detailData) {
            var messageOrderData = _.find(self.orderData, function(orderDataItem) {
                return _.includes(orderDataItem.description, 'DetailData_Message');
            });

            return messageOrderData ? messageOrderData.value.split(';') : null;
        }

        $scope.toggleAll = function() {
            if ($scope.toggleAllState === 'Ja') {
                $scope.toggleAllState = 'Nei';
                var i;
                for (i = 0; i < $scope.routeStop.units.length; i++) {
                    $scope.routeStop.units[i].message = 'Nei';
                }
            } else {
                $scope.toggleAllState = 'Ja';
                for (i = 0; i < $scope.routeStop.units.length; i++) {
                    $scope.routeStop.units[i].message = 'Ja';
                }
            }
        };

        $scope.approve = function(evt, routeStop) {
            preventPropagation(evt);
            if ($scope.OkButtonEnabled()) {
                $windowInstance.close(routeStop);
            } else {
                alert('Nei krever minst et avvik');
            }
        };

        // Units
        $scope.isUnitSelected = function(unit) {
            var isUnitSelected = _.find($scope.selectedAgreementLines, function(a) {
                return a === unit.agreementLines[0];
            });
            return isUnitSelected;
        };

        $scope.initUnitState = function(unit) {
            if (unit.message !== 'Nei') {
                unit.message = 'Ja';
            }
        };

        $scope.toggleUnit = function(unit) {
            var agreementLine = unit.agreementLines[0];
            if ($scope.selectedAgreementLines.indexOf(agreementLine) > -1) {
                $scope.selectedAgreementLines.splice($scope.selectedAgreementLines.indexOf(agreementLine), 1);
            } else {
                $scope.selectedAgreementLines.push(agreementLine);
            }
        };

        $scope.setAllUnitSelected = function(status) {
            if (status === true) {
                $scope.selectedAgreementLines = _.map($scope.routeStop.units, function(u) {
                    return u.agreementLines[0];
                });
            } else {
                $scope.selectedAgreementLines = [];
            }
        };

        $scope.hasDeviation = function(unit) {
            return _.find($scope.routeStop.deviations, {
                agreementLineId: unit.agreementLines[0]
            });
        };

        $scope.deviationCount = function(unit) {
            return _.filter($scope.routeStop.deviations, {
                agreementLineId: unit.agreementLines[0]
            }).length;
        };

        $scope.hasComment = function(unit) {
            return _.find($scope.routeStop.comments, {
                agreementLineId: unit.agreementLines[0]
            });
        };

        $scope.hasPicture = function(unit) {
            return _.includes($scope.unitsWithPicture, unit.agreementLines[0]);
        };

        $scope.pictureCount = function(unit) {
            var picCountObj = _.filter($scope.unitPicCounts, u => u.agreementLineId === unit.agreementLines[0]);
            if (picCountObj && picCountObj.length) {
                return picCountObj[0].picCount;
            }
            return '';
        };

        // Categories
        $scope.getCategoryCheckboxClass = function(category) {
            if ($scope.selectedCategories.indexOf(category) > -1) {
                return 'glyphicon-check';
            }
            return 'glyphicon-unchecked';
        };

        $scope.toggleCategory = function(category) {
            $scope.selectedAllCategory = false;
            if ($scope.selectedCategories.indexOf(category) > -1) {
                $scope.selectedCategories.splice($scope.selectedCategories.indexOf(category), 1);
            } else {
                $scope.selectedCategories.push(category);
            }
        };

        $scope.toggleAllCategory = function() {
            if (
                $scope.selectedCategories.length === $scope.deviationCategories.length &&
                $scope.selectedAllCategory === true
            ) {
                $scope.selectedCategories = [];
                $scope.selectedAllCategory = false;
            } else {
                $scope.selectedCategories = Object.assign([], $scope.deviationCategories);
                $scope.selectedAllCategory = true;
            }
        };

        $scope.getAllCategoryCheckboxClass = function() {
            if (
                $scope.selectedCategories.length === $scope.deviationCategories.length &&
                $scope.selectedAllCategory === true
            ) {
                return 'glyphicon-check';
            }
            return 'glyphicon-unchecked';
        };

        // Deviations
        $scope.getDeviationCheckboxClass = function(deviation) {
            var isDeviationSelected = getIsDeviationSelected(deviation);

            var icon = isDeviationSelected ? 'glyphicon-check' : 'glyphicon-unchecked';

            return icon;
        };

        $scope.toggleDeviation = function(deviation) {
            _.forEach($scope.selectedAgreementLines, function(agreementLine) {
                var filter = {
                    agreementLineId: agreementLine,
                    deviation: {
                        categoryId: deviation.categoryId,
                        messageId: deviation.messageId
                    }
                };
                var index = _.findIndex($scope.routeStop.deviations, filter);

                if (index > -1) {
                    $scope.routeStop.deviations.splice(index, 1);
                } else {
                    $scope.routeStop.deviations.push({
                        agreementLineId: agreementLine,
                        deviation: deviation
                    });
                }
            });
        };

        $scope.isDeviationVisible = function(deviation) {
            if ($scope.selectedCategories.length === 0) {
                return true;
            }

            var ids = _.map($scope.selectedCategories, function(o) {
                return o.categoryId * 1;
            });

            return ids.indexOf(deviation.categoryId * 1) > -1;
        };

        $scope.removeDeviations = function() {
            _.remove($scope.routeStop.deviations, function(deviation) {
                return _.includes($scope.selectedAgreementLines, deviation.agreementLineId);
            });
        };

        $scope.resetAggreementLines = function() {
            deviationService.removeImageFromCache($scope.orderId).then(function() {
                $scope.unitsWithPicture = [];
            });
            $scope.routeStop.deviations = [];
            $scope.routeStop.comments = [];
        };

        $scope.openCommentModal = function(evt) {
            if (!isChildModalOpen) {
                isChildModalOpen = true;

                var commentWindow = $kWindow.open({
                    options: {
                        modal: true,
                        title: 'Kommentar',
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: '95%',
                        maxWidth: 400
                    },
                    templateUrl: 'app/routeStops/modals/comment-modal-view.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'commentModalController',
                    resolve: {
                        routeStop: function() {
                            return $scope.routeStop;
                        },
                        selectedAgreementLines: function() {
                            return $scope.selectedAgreementLines;
                        }
                    }
                });

                commentWindow.result.finally(function() {
                    enableChildModalOpening();
                });
            }
        };

        $scope.OkButtonEnabled = function() {
            if (!isAtLeastOneDeviationRequired()) {
                return true;
            }
            return isAtLeastOneDeviationChecked();
        };

        // Private functions
        function getIsDeviationSelected(deviation) {
            return (
                $scope.selectedAgreementLines.length > 0 &&
                $scope.routeStop.deviations.length > 0 &&
                _.every($scope.selectedAgreementLines, function(agreementLine) {
                    var filter = {
                        agreementLineId: agreementLine,
                        deviation: {
                            categoryId: deviation.categoryId,
                            messageId: deviation.messageId
                        }
                    };
                    return _.find($scope.routeStop.deviations, filter);
                })
            );
        }

        function isAtLeastOneDeviationChecked() {
            if ($scope.routeStop) {
                var deviations = $scope.deviations;
                for (var i = 0; i < deviations.length; i++) {
                    if (getIsDeviationSelected(deviations[i])) {
                        return true;
                    }
                }
            }

            return false;
        }

        function isAtLeastOneDeviationRequired() {
            return (
                $rootScope.noButtonRequiresDeviation &&
                $scope.routeStop &&
                _.filter($scope.routeStop.units, u => u.message === 'Nei').length
            );
        }

        function enableChildModalOpening() {
            setTimeout(function() {
                $scope.$applyAsync(function() {
                    isChildModalOpen = false;
                });
            }, 500);
        }

        function preventPropagation(evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
    }
})();
