(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller(
            "CompletedRouteStopsController",
            completedRouteStopsController
        );

    completedRouteStopsController.$inject = [
        "$q",
        "$state",
        "$rootScope",
        "$scope",
        "$log",
        "$kWindow",
        "locationService",
        "ordersService",
        "orderStatusTransitions",
        "routeStopsService",
        "routeStopUpdateChecker",
        "dynamicRouteListener",
        "orderDetailTypesService",
        "deviationService",
        "settingsService",
        "deviationModalService",
        "commonUtil"
    ];

    function completedRouteStopsController(
        $q,
        $state,
        $rootScope,
        $scope,
        $log,
        $kWindow,
        locationService,
        ordersService,
        orderStatusTransitions,
        routeStopsService,
        routeStopUpdateChecker,
        dynamicRouteListener,
        orderDetailTypesService,
        deviationService,
        settingsService,
        deviationModalService,
        commonUtil
    ) {
        $scope.routeStops = [];
        $scope.filterFunction = null;
        $scope.filteredOrderDetailTypes = [];
        $scope.routeStopsDisplayed = [];
        $scope.orderId = "";
        $scope.loadStatus = "";
        $scope.loadCallbacks = [];

        $scope.isEmpty = isEmpty;
        $scope.alertDisabled = alertDisabled;
        $scope.showInfo = showInfo;
        $scope.undo = undo;
        $scope.openDeviation = openDeviation;
        $scope.stopError = stopError;
        $scope.stopApproved = stopApproved;
        $scope.routeStopClick = routeStopClick;
        $scope.deleteArticle = deleteArticle;
        $scope.firstDeviation = {};
        var deviationList;

        initController();

        function initController() {
            settingsService.getUserType().then(type => {
                $rootScope.userType = type;
            });

            settingsService.getDeviations().then(function(deviations) {
                deviationList = deviationService.sortDeviations(deviations);
                if (deviationList && deviationList.length > 0) {
                    $scope.firstDeviation = deviationList[0];
                }
            });

            if (!$state.params.orderId) {
                $state.go("main.orders");
            }

            $scope.orderId = $state.params.orderId;

            $scope.$on("routeStopUploaded", onRouteStopUploaded);

            $scope.$on("updateReportedRoutes", function(
                eventName,
                reportedRouteStops
            ) {
                routeStopsService.saveReportedRouteStops(
                    $scope.orderId,
                    reportedRouteStops
                );
                handleReportedRoutes(
                    reportedRouteStops,
                    $scope.routeStops
                ).finally(renderRouteStops);
            });

            $scope.$on("routeStopsFilterChanged", function(eventName, filter) {
                var filterValue = filter.value;

                $scope.filterFunction =
                    filterValue.length === 0
                        ? null
                        : function(routeStop) {
                              var propertyValue =
                                  routeStop[filter.option.property];
                              if (filter.option.propertyMapper)
                                  propertyValue = filter.option.propertyMapper(
                                      propertyValue
                                  );

                              if (filter.option.type === "contains")
                                  return propertyValue
                                      .toLowerCase()
                                      .includes(filterValue.toLowerCase());
                              else if (filter.option.type === "equals")
                                  return propertyValue === filterValue;
                              else if (filter.option.type === "lessThan")
                                  return (
                                      _.toNumber(propertyValue) <
                                      _.toNumber(filterValue.replace(",", "."))
                                  );
                              else if (filter.option.type === "greaterThan")
                                  return (
                                      _.toNumber(propertyValue) >
                                      _.toNumber(filterValue.replace(",", "."))
                                  );
                          };

                renderRouteStops();
            });

            $scope.$on("orderDetailTypesFilterChanged", function(
                eventName,
                filteredOrderDetailTypes
            ) {
                $scope.filteredOrderDetailTypes =
                    filteredOrderDetailTypes || [];
                renderRouteStops();
            });

            locationService.getCurrentPosition().then(
                function(position) {
                    loadRouteStops(position);
                },
                function() {
                    loadRouteStops();
                }
            );
        }

        function isEmpty() {
            return (
                _.reject($scope.routeStops, { status: "uncompleted" })
                    .length === 0
            );
        }

        function alertDisabled(evt, routeStop) {
            alert("Rapporten er sendt til fagsystem og kan ikke endres.");
        }

        function showInfo(evt, routeStop) {
            evt.stopPropagation();
            evt.preventDefault();

            let hasItemWithLongText = routeStop.orderData.some(item => {
                if (!item.description || !item.value) {
                    return false;
                }

                return (
                    item.description.length > 20 ||
                    commonUtil.getOrderDataItemSanitizedValue(item).length > 15
                );
            });

            let modalOptions = {
                modal: true,
                title: "Info",
                draggable: false,
                movable: false,
                resizable: false,
                visible: false,
                close: function() {
                    $rootScope.$broadcast("hideBusyIndicator"); //hide indicator when closed with 'x' button
                }
            };

            if (hasItemWithLongText) {
                modalOptions.width = "90%";
            }

            $kWindow.open({
                options: modalOptions,
                templateUrl: "app/routeStops/modals/route-stop-info-view.html",
                windowTemplateUrl: "app/shared/modal-base.html",
                controller: "RouteStopInfoController",
                resolve: {
                    orderData: function() {
                        return routeStop.orderData;
                    },
                    routeStop: function() {
                        return routeStop;
                    }
                }
            });
        }

        function undo(evt, routeStop) {
            evt.stopPropagation();
            evt.preventDefault();

            routeStop.successful = "";
            routeStop.message = "";
            routeStop.status = "uncompleted";
            routeStop.time = null;
            routeStop.isSelected = false;

            _.remove($scope.routeStopsDisplayed, function(rs) {
                return rs.routeLineId === routeStop.routeLineId;
            });

            routeStopsService.updateRouteStop($scope.orderId, routeStop).then(
                function() {},
                function() {
                    // error occurred
                }
            );
        }

        function openDeviation(evt, routeStop) {
            preventPropagation(evt);

            var options = {
                orderId: routeStop.callOrderId || $scope.orderId,
                routeStop,
                deviationList
            };
            var deviationWindow = deviationModalService.create(options);

            deviationWindow.result.then(function(deviationRouteStop) {
                if (deviationRouteStop) {
                    var routeStopIndex = _.findIndex($scope.routeStops, {
                        routeLineId: deviationRouteStop.routeLineId
                    });
                    $scope.routeStops[routeStopIndex] = deviationRouteStop;

                    if (_.find(deviationRouteStop.units, { message: "Nei" })) {
                        deviationRouteStop.successful = "unsuccessful";
                    } else {
                        deviationRouteStop.successful = "successful";
                    }

                    handleRouteStopCompleted(deviationRouteStop);
                    renderRouteStops();
                }
            });
        }

        function deleteArticle(evt, routeStop, article) {
            preventPropagation(evt);
            if (routeStop.status !== "uploaded") {
                var index = _.findIndex(
                    routeStop.articles,
                    a => a.articleId === article.articleId
                );
                if (index > -1) {
                    routeStop.articles.splice(index, 1);
                }
                routeStopsService.saveLocalRouteStop($scope.orderId, routeStop);
            }
        }

        function stopError(evt, routeStop) {
            preventPropagation(evt);

            routeStop.successful = "unsuccessful";
            routeStop.message = "Nei";
            routeStop.isAutoComplete = "False";
            handleRouteStopCompleted(routeStop);
        }

        function stopApproved(evt, routeStop) {
            preventPropagation(evt);

            routeStop.successful = "successful";
            routeStop.message = "Ja";
            routeStop.isAutoComplete = "False";
            handleRouteStopCompleted(routeStop);
        }

        function routeStopClick(routeStop) {
            //unselect all others
            _.forEach($scope.routeStops, function(stop) {
                if (routeStop.routeLineId !== stop.routeLineId) {
                    stop.isSelected = false;
                }
            });

            routeStop.isSelected = !routeStop.isSelected;
        }

        //Private functions
        function preventPropagation(evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }

        function onRouteStopUploaded(eventName, routeStop) {
            var func = function() {
                var completedRouteStop = _.find($scope.routeStops, {
                    routeLineId: routeStop.routeLineId
                });

                if (completedRouteStop) {
                    completedRouteStop.status = "uploaded";
                }
            };

            if ($scope.loadStatus !== "") func();
            else $scope.loadCallbacks.unshift(func);
        }

        function handleRouteStopCompleted(routeStop) {
            routeStop.status = "completed";
            routeStop.time = moment().format();

            locationService
                .getCurrentPosition()
                .then(function(currentPosition) {
                    routeStop.assignLogData(currentPosition);
                })
                .catch(function(error) {
                    $log.error("failed to assign log data: " + error);
                })
                .then(function() {
                    return settingsService.getActiveDriver();
                })
                .then(function(activeDriver) {
                    if (activeDriver) {
                        routeStop.driverId = activeDriver.driverId;
                    }
                    return routeStopsService.updateRouteStop(
                        $scope.orderId,
                        routeStop
                    );
                })
                .catch(function(error) {
                    $log.error(error);
                });
        }

        function loadRouteStops(position) {
            $rootScope.$broadcast("showBusyIndicator");
            var isAjour = $rootScope.userType === "ajour";
            var requests = [
                ordersService.getOrder($scope.orderId),
                routeStopsService.getRoute($scope.orderId, position, isAjour),
                orderDetailTypesService.getSelectedOrderDetailTypes()
            ];

            var route;
            $q.all(requests)
                .then(function(data) {
                    var order = data[0];
                    route = data[1];
                    $scope.filteredOrderDetailTypes =
                        $rootScope.userType === "service" ? data[2] || [] : [];

                    $rootScope.$broadcast("routeLoaded", order.routeName);

                    if (order.orderType === 6) {
                        dynamicRouteListener.start($scope.orderId);
                        return routeStopsService.getReportedRoute(
                            $scope.orderId,
                            true
                        );
                    }
                })
                .then(function(reportedRouteStops) {
                    if (reportedRouteStops)
                        return handleReportedRoutes(
                            reportedRouteStops,
                            route.routeStops
                        );
                })
                .finally(function() {
                    $scope.routeStops = route.routeStops.sort(sortRouteStops);
                    renderRouteStops();
                    $scope.loadStatus = "loaded";

                    _.forEach($scope.loadCallbacks, function(loadCallback) {
                        loadCallback();
                    });

                    $rootScope.$broadcast("hideBusyIndicator");
                });
        }

        function sortRouteStops(a, b) {
            return !a.time && !b.time
                ? 0
                : !a.time && b.time
                ? 1
                : !b.time && a.time
                ? -1
                : a.time > b.time
                ? -1
                : 1;
        }

        function handleReportedRoutes(reportedRouteStops, collection) {
            var deferred = $q.defer();

            var requests = _.map(
                _.filter(
                    _.map(reportedRouteStops, function(reportedRouteStop) {
                        return _.find(collection, {
                            routeLineId: reportedRouteStop.routeLineId
                        });
                    }),
                    function(routeStop) {
                        return routeStop && routeStop.status !== "uploaded";
                    }
                ),
                function(routeStop) {
                    routeStop.status = "uploaded";
                    routeStop.successful = "unknown";
                    return routeStopsService.saveLocalRouteStop(
                        $scope.orderId,
                        routeStop
                    );
                }
            );

            $q.all(requests).then(
                function() {
                    checkRouteCompleted();
                    deferred.resolve();
                },
                function() {
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function checkRouteCompleted() {
            if (
                $scope.routeStops.length > 0 &&
                !_.some($scope.routeStops, ["status", "uncompleted"])
            ) {
                kendo.alert("Rute fullført!");

                $rootScope.$broadcast("showBusyIndicator");

                routeStopUpdateChecker
                    .scheduleUpdates($scope.orderId)
                    .then(function() {
                        return ordersService.updateOrderStatus(
                            $scope.orderId,
                            orderStatusTransitions.complete
                        );
                    })
                    .then(function() {
                        $state.go("main.orders");
                    });
            }
        }

        function renderRouteStops() {
            var filteredRouteStops = _.filter($scope.routeStops, function(
                routeStop
            ) {
                return routeStop.status !== "uncompleted";
            });

            if ($scope.filterFunction) {
                filteredRouteStops = _.filter(
                    filteredRouteStops,
                    $scope.filterFunction
                );
            }

            if ($scope.filteredOrderDetailTypes.length > 0) {
                filteredRouteStops = _.filter(filteredRouteStops, function(
                    routeStop
                ) {
                    return _.some(routeStop.units, function(unit) {
                        return (
                            _.intersection(
                                $scope.filteredOrderDetailTypes,
                                unit.orderDetailTypes
                            ).length > 0
                        );
                    });
                });
            }

            $scope.routeStopsDisplayed = filteredRouteStops;
        }
    }
})();
