(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('cacheMergeService', cacheMergeService);

    cacheMergeService.$inject = ['$q', '$localForage', 'cacheService', 'authService', 'errorReportService', 'appSettings'];

    function cacheMergeService($q, $localForage, cacheService, authService, errorReportService, appSettings) {

        var service = {
            moveKeysToLowerCaseUsername: moveKeysToLowerCaseUsername
        };

        return service;

        function isCacheMixed(lowerCaseItems, firstUpperCaseItems, upperCaseItems) {
            var allKeysLength = lowerCaseItems.length + firstUpperCaseItems.length + upperCaseItems.length;
            return !(allKeysLength === lowerCaseItems.length
                || allKeysLength === firstUpperCaseItems.length
                || allKeysLength === upperCaseItems.length);
        }

        function moveKeysToLowerCaseUsername() {

            var deferred = $q.defer();

            var latestUsername;
            authService.getAuthData()
                .then(function (authData) {
                    latestUsername = authData.username;
                    var lowerCaseUsername = latestUsername.toLowerCase();
                    var firstUpperCaseUsername = lowerCaseUsername.slice(0, 1).toUpperCase() + lowerCaseUsername.slice(1, lowerCaseUsername.length);
                    var upperCaseUsername = latestUsername.toUpperCase();

                    var lowerCaseItems;
                    var firstUpperCaseItems;
                    var upperCaseItems;
                    var masterCache;

                    cacheService.getAllKeysWithoutUserPrefix()
                        .then(allCacheKeys => {
                            lowerCaseItems = _.filter(allCacheKeys, item => item.key.startsWith(lowerCaseUsername));
                            firstUpperCaseItems =
                                _.filter(allCacheKeys, item => item.key.startsWith(firstUpperCaseUsername));
                            upperCaseItems = _.filter(allCacheKeys, item => item.key.startsWith(upperCaseUsername));

                            if (isCacheMixed(lowerCaseItems, firstUpperCaseItems, upperCaseItems)) {
                                return errorReportService.sendErrorReport("mixed casing logins found " + latestUsername,
                                    true);
                            } else {
                                deferred.resolve();
                            }
                        })
                        .then(() => {
                            //error report sent
                            if (appSettings.cacheCasingMigrationEnabled) {

                                switch (latestUsername) {
                                    case lowerCaseUsername:
                                        {
                                            masterCache = lowerCaseItems;
                                            break;
                                        }
                                    case firstUpperCaseUsername:
                                        {
                                            masterCache = firstUpperCaseItems;
                                            break;
                                        }
                                    case upperCaseUsername:
                                        {
                                            masterCache = upperCaseItems;
                                            break;
                                        }
                                    default:
                                        {
                                            deferred.reject("username unexpected format");
                                        }
                                }

                                if (masterCache && masterCache.length) {

                                    var removableKeys =
                                        _.map(lowerCaseItems.concat(firstUpperCaseItems).concat(upperCaseItems), 'key');
                                    var removeKeyRequests = _.map(removableKeys, key => $localForage.removeItem(key));

                                    return $q.all(removeKeyRequests);

                                } else {
                                    deferred.reject();
                                }
                            } else {
                                deferred.resolve();
                            }
                        })
                        .then(() => {
                            if (appSettings.cacheCasingMigrationEnabled) {
                                //keys removed, lower casing master keys
                                _.forEach(masterCache,
                                    item => {
                                        var parts = item.key.split('_');
                                        item.key = parts[0].toLowerCase() + "_" + parts.slice(1, parts.length).join('_');
                                    });

                                var addKeyRequests = _.map(masterCache, item => $localForage.setItem(item.key, item.value));
                                return $q.all(addKeyRequests);
                            }
                        })
                        .then(() => {
                            deferred.resolve();
                        });
                });

            return deferred.promise;

        }


    }


})();
