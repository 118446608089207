(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('LoginController', loginController);

    loginController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$state',
        'appVersion',
        'authService',
        'settingsService',
        'orderDetailTypesService',
        'articlesService',
        'bluetoothService'
    ];

    function loginController(
        $q,
        $rootScope,
        $scope,
        $state,
        appVersion,
        authService,
        settingsService,
        orderDetailTypesService,
        articlesService,
        bluetoothService
    ) {
        $scope.appVersion = appVersion;
        $scope.login = login;
        $scope.loginInProgress = false;

        if (navigator.userAgent.search('SamsungBrowser') >= 0) {
            //Samsung browser is not supported, we recommend you to use chrome, otherwise the application might not work correctly
            var samsungBrowserNotSupportedMsg =
                'Samsung internett nettleser er ikke støttet, vi anbefaler at du bruker Chrome. Det kan forekomme at applikasjonen ikke fungerer som den skal i Samsung internett nettleser';
            alert(samsungBrowserNotSupportedMsg);
        }

        initController();

        function initController() { }

        function login(username, password) {
            if (username && password) {
                if (!$scope.loginInProgress) {
                    $scope.loginInProgress = true;
                    $rootScope.$broadcast('showBusyIndicator');

                    authService
                        .login(username, password)
                        .then(function () {
                            var requests = [
                                settingsService.getSettings(true),
                                settingsService.getVehicleSettings(true),
                                articlesService.getArticles(true)
                            ];

                            return $q.all(requests);
                        })
                        .then(function (data) {
                            $rootScope.userSettings = data[0];
                            $rootScope.vehicleSettings = data[1];

                            settingsService.getUserType().then(function (userType) {
                                $rootScope.userType = userType;

                                if (userType === 'service') {
                                    orderDetailTypesService.getOrderDetailTypes(true).then(function (orderDetailTypes) {
                                        $rootScope.orderDetailTypes = orderDetailTypes;
                                    });
                                }
                            });

                            settingsService
                                .getEnableTracing()
                                .then(enableTracing => ($rootScope.enableTracing = enableTracing));
                            settingsService
                                .getCallOrderCheckInterval()
                                .then(
                                    callOrderCheckInterval =>
                                        ($rootScope.callOrderCheckInterval = callOrderCheckInterval)
                                );
                            settingsService.getDisableAnimation().then(disableAnimation => {
                                $.fn.kendoWindow.widget.prototype.options.animation = !disableAnimation;
                            });

                            settingsService.getIsDataButtonsEnabled().then(function (isDataButtonsEnabled) {
                                $rootScope.isDataButtonsEnabled = isDataButtonsEnabled;
                            });

                            settingsService
                                .getAutoModeToggleButtonVisible()
                                .then(function (autoModeToggleButtonVisible) {
                                    $rootScope.autoModeToggleButtonVisible = autoModeToggleButtonVisible;
                                });

                            settingsService.getException1ButtonVisible().then(function (exception1ButtonVisible) {
                                $rootScope.isDeviation1ButtonVisible = exception1ButtonVisible;
                            });

                            settingsService
                                .getVoltAdjustUrl()
                                .then(voltAdjustUrl => ($rootScope.voltAdjustUrl = voltAdjustUrl));

                            settingsService.getIsAjourUser().then(isAjour => ($rootScope.isAjour = isAjour));

                            settingsService
                                .getIsWeightUnitBotek()
                                .then(isWeightUnitBotek => ($rootScope.isWeightUnitBotek = isWeightUnitBotek));

                            settingsService.getEnableWeight().then(enableWeight => {
                                if (enableWeight) {
                                    $rootScope.enableWeight = enableWeight;
                                    bluetoothService.attachBotekWeightUnitDevice(true);
                                }
                            });

                            if ($rootScope.userSettings.isReadOnly) {
                                alert('Demomodus - Ikke registrert bruker for DTA Universal');
                            }

                            $state.go('main.orders');
                            $scope.loginInProgress = false;
                        })
                        .catch(function () {
                            $rootScope.$broadcast('hideBusyIndicator');
                            showError();
                            $scope.loginInProgress = false;
                        });
                }
            } else {
                $rootScope.$broadcast('hideBusyIndicator');
                showError();
            }
        }

        function showError() {
            alert('Feil brukernavn eller passord');
        }
    }
})();
