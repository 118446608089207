(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("MessagesController", messagesController);

    messagesController.$inject = [
        "$rootScope",
        "$scope",
        "messagesService",
        "messageState"
    ];

    function messagesController(
        $rootScope,
        $scope,
        messagesService,
        messageState
    ) {
        $scope.isNew = isNew;
        $scope.messageClick = messageClick;

        initController();

        function initController() {}

        function isNew(message) {
            return message.messageState === messageState.new;
        }

        function messageClick(message) {
            //unselect all others
            _.forEach($rootScope.directMessages, function(m) {
                if (m.id !== message.id) {
                    m.isSelected = false;
                }
            });

            message.isSelected = !message.isSelected;

            if (message.messageState === messageState.new) {
                messagesService
                    .updateMessageStatus(message.id)
                    .then(function() {
                        message.messageState = messageState.readProfile;
                        $rootScope.messageBadgeCount--;

                        if ($rootScope.messageBadgeCount === 0) {
                            $rootScope.showMessageBadge = false;
                        }
                    });
            }
        }
    }
})();
