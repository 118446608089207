(function() {
    angular
        .module("PWAPoCApp")
        .controller("NearbyRouteStopsController", nearbyRouteStopsController);

    nearbyRouteStopsController.$inject = [
        "$scope",
        "$rootScope",
        "$windowInstance",
        "$log",
        "appSettings",
        "locationService",
        "routeStopsService",
        "addedRouteStops",
        "RouteStop",
        "nearbyRouteStopsService",
        "authService",
        "orderId",
        "appVersion"
    ];

    function nearbyRouteStopsController(
        $scope,
        $rootScope,
        $windowInstance,
        $log,
        appSettings,
        locationService,
        routeStopsService,
        addedRouteStops,
        RouteStop,
        nearbyRouteStopsService,
        authService,
        orderId,
        appVersion
    ) {
        $scope.loadNearbyRouteStops = loadNearbyRouteStops;
        $scope.addRouteStop = addRouteStop;
        $scope.displayMessage = "";
        $scope.sliderReady = false;
        $scope.isLoading = true;
        var controllerInitialized = false;
        var addedNearbyRsList = addedRouteStops;
        var currentPosition;
        var currentOrderId = orderId;
        const offlineMessageText =
            "Mangler internettdekning. Ikke mulig å laste ned nærliggende hentesteder";
        $scope.isOnlineMode = $rootScope.isOnline;

        $rootScope.$on("closeNearbyRouteStopWindow", function() {
            $windowInstance.close(null);
        });
        if ($rootScope.isOnline) {
            $scope.displayMessage = "";
            $scope.loadNearbyRouteStops();
        } else {
            //You are not connected to the network - cannot load nearby route stops
            $scope.displayMessage = offlineMessageText;
            $scope.isLoading = false;
        }

        function loadNearbyRouteStops() {
            nearbyRouteStopsService.getNearbyDistance().then(function(dist) {
                var distance = dist ? dist : appSettings.nearbyRouteStopDist;
                $scope.nearbyDistance = distance;
                $scope.nearbyRouteStops = [];
                updateNearbyRouteStopsList();
            });
        }

        function updateNearbyRouteStopsList() {
            $scope.nearbyRouteStops = [];
            showBusyIndicator();
            locationService.getCurrentPosition().then(
                function(pos) {
                    currentPosition = pos;
                    var lat = currentPosition.coords.latitude;
                    var lng = currentPosition.coords.longitude;
                    routeStopsService
                        .getRouteLinesInRadius(
                            $scope.nearbyDistance,
                            appSettings.nearbyMaxHits,
                            lat,
                            lng
                        )
                        .then(
                            function(routeLines) {
                                var routeStops = _.map(routeLines, function(
                                    rl
                                ) {
                                    return createRouteStop(
                                        rl,
                                        $rootScope.authData.customerId
                                    );
                                });
                                $scope.nearbyRouteStops = _.reject(
                                    routeStops,
                                    isAdded
                                );
                                if (!$scope.nearbyRouteStops.length) {
                                    $scope.displayMessage = "Ingen data.";
                                } else {
                                    $scope.displayMessage = null;
                                }
                                if (!controllerInitialized) {
                                    initController();
                                }
                                hideBusyIndicator();
                            },
                            function(err) {
                                setErrorDisplayMessage();
                                hideBusyIndicator();
                            }
                        );
                },
                function(err) {
                    setErrorDisplayMessage();
                    hideBusyIndicator();
                }
            );
        }

        function setErrorDisplayMessage() {
            $scope.displayMessage = offlineMessageText;
        }

        function initController() {
            controllerInitialized = true;
            $(".nearby-slider").slider({
                min: 100,
                max: 1000,
                step: 50,
                value: $scope.nearbyDistance
            });

            $(".nearby-slider").on("slidestop", function(event, ui) {
                $scope.nearbyDistance = ui.value;
                updateNearbyRouteStopsList();
            });

            $scope.sliderReady = true;
            $windowInstance.result.finally(function() {
                saveDistanceToCache();
                hideBusyIndicator();
            });
        }

        function createRouteStop(rLine, customerId) {
            var routeStop = new RouteStop(
                rLine,
                currentPosition,
                $rootScope.userSettings.dataButtons
            );
            routeStop.customerId = customerId;
            routeStop.appVersion = appVersion;
            routeStop.isNearbyRouteStop = true;
            return routeStop;
        }

        function isAdded(routeStop) {
            return (
                _.findIndex(addedNearbyRsList, function(rs) {
                    return (
                        routeStop.units[0].containerId ===
                        rs.units[0].containerId
                    );
                }) > -1
            );
        }

        function addRouteStop(routeStop) {
            if (!$scope.isLoading) {
                routeStopsService
                    .addRouteStopToLocalRoute(routeStop, currentOrderId)
                    .then(
                        function() {},
                        function(err) {
                            $log.error(
                                "error saving nearby route stop: " +
                                    (err ? err : "undefined")
                            );
                        }
                    );

                saveDistanceToCache();
                $windowInstance.close(routeStop);
            }
        }

        function saveDistanceToCache() {
            nearbyRouteStopsService
                .saveNearbyDistance($scope.nearbyDistance)
                .then(
                    function() {},
                    function(err) {
                        $log.error(err);
                    }
                );
        }

        function showBusyIndicator() {
            $rootScope.$broadcast("showBusyIndicator", {
                message: "Laster ned..."
            });
            $scope.isLoading = true;
        }

        function hideBusyIndicator() {
            $rootScope.$broadcast("hideBusyIndicator");
            $scope.isLoading = false;
        }
    }
})();
