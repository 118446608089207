(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("articlesController", articlesController);

    articlesController.$inject = [
        "$rootScope",
        "$scope",
        "$windowInstance",
        "downloadedArticles",
        "routeStop"
    ];

    function articlesController(
        $rootScope,
        $scope,
        $windowInstance,
        downloadedArticles,
        routeStop
    ) {
        var currentRouteStop = routeStop;
        var offlineMessageText = "Mangler internettdekning.";
        $scope.selectedArticle = null;
        $scope.quantity = null;
        $scope.downloadedArticles = downloadedArticles;
        $scope.quantityText = "";

        $scope.isAddArticleEnabled = function() {
            if ($scope.quantityText) {
                $scope.quantity = _.toNumber(
                    $scope.quantityText.replace(",", ".")
                );
                return (
                    $scope.selectedArticle &&
                    $scope.quantityText &&
                    $scope.quantity &&
                    $scope.quantity > 0
                );
            }
            return false;
        };

        $scope.selectArticle = function(article) {
            $scope.selectedArticle = article;
        };

        $scope.isSelected = function(article) {
            return $scope.selectedArticle === article;
        };

        function addArticle(article) {
            if (!currentRouteStop.articles) {
                currentRouteStop.articles = [];
            }

            if (
                _.indexOf(currentRouteStop.articles, function(a) {
                    return a.articleId === article.articleId;
                }) === -1
            ) {
                currentRouteStop.articles.push(article);
            }
        }

        $scope.ok = function() {
            if ($scope.isAddArticleEnabled()) {
                var quantity = $scope.quantity;
                if (quantity > 0) {
                    var articleIndex = _.findIndex(
                        routeStop.articles,
                        a => a.articleId === $scope.selectedArticle.articleId
                    );
                    if (articleIndex > -1) {
                        routeStop.articles[articleIndex].value += quantity;
                    } else {
                        var articleObj = _.cloneDeep($scope.selectedArticle);
                        articleObj.value = quantity;
                        addArticle(articleObj);
                    }

                    $windowInstance.close(routeStop);
                }
            }
        };

        $scope.cancel = function() {
            $windowInstance.close();
        };

        initController();

        function onlyNumber(val) {
            return /^\d+$/.test(val);
        }

        function initController() {
            if ($rootScope.isOnline) {
                $scope.displayMessage = "";
            } else {
                //You are not connected to the network - cannot load nearby route stops
                $scope.displayMessage = offlineMessageText;
                $scope.isLoading = false;
            }
        }
    }
})();
