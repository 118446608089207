(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("RouteStopMessageController", routeStopMessageController);

    routeStopMessageController.$inject = [
        "$scope",
        "$windowInstance",
        "message"
    ];

    function routeStopMessageController($scope, $windowInstance, message) {
        $scope.message = message;
        $scope.ok = ok;

        function ok() {
            $windowInstance.close();
        }
    }
})();
