(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller(
            "itemTypeReportModalController",
            itemTypeReportModalController
        );

    itemTypeReportModalController.$inject = [
        "$scope",
        "$windowInstance",
        "orderItemTypes",
        "routeStops",
        "orderItemTypeService"
    ];

    function itemTypeReportModalController(
        $scope,
        $windowInstance,
        orderItemTypes,
        routeStops,
        orderItemTypeService
    ) {
        //get order item type groups and group them by unit types

        var units = _.flatten(_.map(routeStops, rs => rs.units));
        $scope.inUnitGroup = createUnitGroup(
            units,
            u => u.orderItemType === orderItemTypes.in,
            orderItemTypes.in
        );
        $scope.outUnitGroup = createUnitGroup(
            units,
            u => u.orderItemType === orderItemTypes.out,
            orderItemTypes.out
        );
        $scope.serviceUnitGroup = createUnitGroup(
            units,
            u => u.orderItemType === orderItemTypes.service,
            orderItemTypes.service
        );

        var otherGroup = createUnitGroup(
            units,
            u =>
                u.orderItemType !== orderItemTypes.in &&
                u.orderItemType !== orderItemTypes.out &&
                u.orderItemType !== orderItemTypes.service
        );
        $scope.otherUnitGroup = {
            icon: otherGroup.icon,
            units: _.orderBy(otherGroup.units, "name")
        };

        $scope.notNullOrEmpty = function(unitGroup) {
            return unitGroup && unitGroup.units && unitGroup.units.length;
        };

        $scope.closeReport = function() {
            $windowInstance.close(true);
        };

        function createUnitGroup(units, predicate, itemType) {
            var unitsWithItemType = _.filter(units, predicate);
            var unitGroup = _.map(_.uniqBy(unitsWithItemType, "name"), u => ({
                name: u.name,
                count: _.filter(unitsWithItemType, { name: u.name }).length
            }));

            return {
                units: _.orderBy(unitGroup, "name"),
                icon: orderItemTypeService.getIcon(itemType)
            };
        }
    }
})();
