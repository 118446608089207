(function () {
    var pwaPoCApp = angular.module('PWAPoCApp', ['ui.router', 'LocalForageModule', 'kendo.directives', 'kendo.window', 'leaflet-directive', 'bc.AngularKeypad', 'ngStorage']).value('THROTTLE_MILLISECONDS', null);

    pwaPoCApp.config(['$locationProvider', '$httpProvider', '$localForageProvider', function ($locationProvider, $httpProvider, $localForageProvider) {
        $locationProvider.html5Mode(true);
        $httpProvider.interceptors.push('authInterceptorService');
        $httpProvider.interceptors.push('logInterceptorService');
        $httpProvider.interceptors.push('offlineInterceptor');
    }]);

    pwaPoCApp.constant('formTypes', {
        add: 'add',
        edit: 'edit'
    });

    pwaPoCApp.constant('orderStatusTransitions', {
        start: 0,
        cancel: 1,
        complete: 2,
        delete: 3
    });

    pwaPoCApp.run(appInit);

    appInit.$inject = ['$q', '$rootScope', '$state', '$window', '$timeout', '$localStorage', 'authService', 'cacheService', 'networkService', 'healthInfoService', 'settingsService', 'callOrdersService', 'messagesService', 'routeStopUpdateChecker', 'updateQueue', 'appSettings', 'orderDetailTypesService', 'containerOrdersService'];

    function appInit($q, $rootScope, $state, $window, $timeout, $localStorage, authService, cacheService, networkService, healthInfoService, settingsService, callOrdersService, messagesService, routeStopUpdateChecker, updateQueue, appSettings, orderDetailTypesService, containerOrdersService) {
        $rootScope.authData = {};
        $rootScope.showNavbar = false;
        $rootScope.$localStorage = $localStorage;

        init();

        function init() {

            cacheService.migrate()
                .then(function () {
                    return authService.getAuthData();
                })
                .then(function (authData) {
                    $rootScope.authData = authData;

                    checkIfOnlineAndAuth().then(updateHealthInfo);

                    //Load settings for user
                    if ($rootScope.authData.isAuth) {
                        var requests = [
                            settingsService.getSettings(),
                            settingsService.getVehicleSettings(),
                            settingsService.getUserType(),
                            settingsService.getIsDataButtonsEnabled(),
                            settingsService.getVoltAdjustUrl()
                        ];

                        $q.all(requests).then(function (data) {
                            $rootScope.userSettings = data[0];
                            $rootScope.vehicleSettings = data[1];
                            $rootScope.userType = data[2];
                            $rootScope.isDataButtonsEnabled = data[3];
                            $rootScope.voltAdjustUrl = data[4];

                            if ($rootScope.userType === 'service') {
                                orderDetailTypesService.getOrderDetailTypes(true).then(function (orderDetailTypes) {
                                    $rootScope.orderDetailTypes = orderDetailTypes;
                                });
                            }
                        });

                        callOrdersService.startPolling();
                        messagesService.startMessagePolling();
                    }
                });
        }

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            $rootScope.activeOrderId = toParams.orderId;

            if (toState.name !== 'error') {
                if (browserNotSupported($window.navigator.userAgent)) {
                    event.preventDefault();

                    $state.transitionTo('error');
                }

                authService.getAuthData().then(function (authData) {
                    if (authData.isAuth) {
                        if (!authService.isUpToDate()) {
                            event.preventDefault();

                            authService.logout().then(function () {
                                $state.transitionTo('login');
                            }, function () {
                                $state.transitionTo('login');
                            });
                        }

                        if (fromState.name === 'main.routeStops' || fromState.name === 'main.completedRouteStops') {
                            if (toState.name === 'main.map') {
                                toParams.orderId = fromParams.orderId;
                                if (fromState.name === 'main.completedRouteStops') {
                                    toParams.completed = true;
                                }
                            }
                        } else if ($rootScope.userType === 'service' && toState.name === 'main.routeStops') {
                            orderDetailTypesService.clearSelectedOrderDetailTypes().then(function () {
                                $rootScope.$broadcast('orderDetailTypesFilterChanged', []);
                            });
                        }

                        if (toState.name === 'login') {
                            event.preventDefault();
                            transitionToCachedState();
                        } else {
                            $rootScope.$localStorage.applicationState = { state: toState, params: toParams };
                        }
                    } else if ((toState.name !== 'login')) {
                        event.preventDefault();
                        $state.transitionTo('login');
                    }
                });
            }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if (toState.name === 'login') {
                $rootScope.showNavbar = false;
            } else {
                $rootScope.showNavbar = true;
            }

            $rootScope.$broadcast('hideBusyIndicator');
        });

        var hasMediaDeviceInitialized = false,
            userHasMediaDevice = false;

        $rootScope.hasMediaDevice = function () {
            var deferred = $q.defer();

            if (navigator && navigator.mediaDevices) {
                if (hasMediaDeviceInitialized) {
                    deferred.resolve(userHasMediaDevice);
                } else {
                    navigator.mediaDevices.getUserMedia({ video: { facingMode: { ideal: "environment" } } }).then(
                        function () {
                            userHasMediaDevice = true;
                            deferred.resolve(userHasMediaDevice);
                        }).catch(function () {
                            userHasMediaDevice = false;
                            deferred.resolve(userHasMediaDevice);
                        });
                }
            } else {
                deferred.resolve(false);
            }

            return deferred.promise;
        };

        $rootScope.connectionChanged = function () {
            if ($rootScope.ping) $timeout.cancel($rootScope.ping);
            if ($rootScope.healthInfoUpdate) $timeout.cancel($rootScope.healthInfoUpdate);

            checkIfOnlineAndAuth().then(updateHealthInfo);
        };

        function transitionToCachedState() {
            var appState = $rootScope.$localStorage.applicationState;
            if (appState && appState.state && appState.state.name && appState.params) {
                $state.go(appState.state.name, appState.params);
            } else {
                $state.go('main.orders');
            }
        }

        function checkIfOnlineAndAuth() {
            var deferred = $q.defer();

            networkService.isOnline()
                .then(function (isOnline) {
                    $rootScope.isOnline = isOnline;

                    if ($rootScope.authData.token) {
                        if (isOnline) {
                            callOrdersService.startPolling();

                            if ($rootScope.userType === "container") {
                                containerOrdersService.startPolling();
                            }
                            messagesService.startMessagePolling();
                            updateQueue.triggerUpdates();
                            $rootScope.$broadcast('connectionChanged', "online");
                        } else {
                            callOrdersService.stopPolling();
                            containerOrdersService.stopPolling();
                            messagesService.stopMessagePolling();
                            $rootScope.$broadcast('connectionChanged', "offline");
                        }

                        routeStopUpdateChecker.scheduleUpdates();
                    } else {
                        callOrdersService.stopPolling();
                        containerOrdersService.stopPolling();
                        messagesService.stopMessagePolling();
                    }

                    deferred.resolve();
                })
                .finally(function () {
                    var timeoutMs = $rootScope.isOnline ? appSettings.ping.online : appSettings.ping.offline;
                    if ($rootScope.ping) $timeout.cancel($rootScope.ping);
                    $rootScope.ping = $timeout(checkIfOnlineAndAuth, timeoutMs);
                });

            return deferred.promise;
        }

        function updateHealthInfo() {
            if ($rootScope.isOnline && $rootScope.authData.token) healthInfoService.update();

            var timeoutMs = appSettings.healthInfoUpdateMs;
            if ($rootScope.healthInfoUpdate) $timeout.cancel($rootScope.healthInfoUpdate);
            $rootScope.healthInfoUpdate = $timeout(updateHealthInfo, timeoutMs);
        }
    }

    pwaPoCApp.run(updateActionTypeSetup);

    updateActionTypeSetup.$inject = ['$q', 'routeStopsService', 'ordersService', 'messagesService', 'imageService', 'updateQueue'];

    function updateActionTypeSetup($q, routeStopsService, ordersService, messagesService, imageService, updateQueue) {

        var updateActionTypes = [
            {
                type: 'updateRouteStop',
                callback: routeStopsService.updateRouteStopRequest
            },
            {
                type: 'updateOrderStatus',
                callback: ordersService.updateOrderStatusRequest
            },
            {
                type: 'deleteRoute',
                callback: routeStopsService.deleteRouteRequest
            },
            {
                type: 'deleteOrder',
                callback: ordersService.deleteOrderRequest
            },
            {
                type: 'updateMessageStatus',
                callback: messagesService.updateMessageStatusRequest
            },
            {
                type: 'uploadImage',
                callback: uploadImageRequest
            }
        ];

        updateQueue.setUpdateActionTypes(updateActionTypes);

        function uploadImageRequest(orderId, routeLineId, agreementLineIds, imageHash) {
            var deferred = $q.defer();

            var uploadedPictureUrl;
            imageService.uploadImage(imageHash)
                .then(function (pictureUrl) {
                    uploadedPictureUrl = pictureUrl;
                    return routeStopsService.getLocalRouteStop(orderId, routeLineId);
                })
                .then(function (routeStop) {
                    _.forEach(agreementLineIds, function (agreementLineId) {
                        routeStop.deviationPictures.push({ agreementLineId: agreementLineId, pictureUrl: uploadedPictureUrl });
                    });
                    return routeStopsService.saveLocalRouteStop(orderId, routeStop);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }

    function browserNotSupported(userAgent) {
        return (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0);
    }
})();
