(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('orderDateFilter', orderDateFilter);

    orderDateFilter.$inject = [];

    function orderDateFilter() {
        var directive = {
            controller: [
                '$scope',
                'containerOrdersService',
                'orderDateFilterOptions',
                orderDateFilter,
            ],
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: 'app/shared/directives/order-date-filter.html'
        };

        return directive;

        function orderDateFilter(
            $scope,
            containerOrdersService,
            orderDateFilterOptions,
        ) {
            $scope.filterOptions = orderDateFilterOptions;
            $scope.selectedOption = orderDateFilterOptions[0];

            $scope.filterClicked = filterClicked;

            initController();

            function initController() {
                const value = containerOrdersService.getOrderDateFilter();
                $scope.selectedOption = orderDateFilterOptions.find(o => o.value === value)
                    || $scope.selectedOption;
            }

            function filterClicked(filterOption) {
                if ($scope.selectedOption === filterOption) return;
                
                $scope.selectedOption = filterOption;
                containerOrdersService.setOrderDateFilter(filterOption.value);
            }
        }
    }
})();
