(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("ImageListModalController", imageListModalController);

    imageListModalController.$inject = [
        "$q",
        "$scope",
        "$kWindow",
        "$rootScope",
        "$windowInstance",
        "$timeout",
        "$log",
        "orderId",
        "routeStop",
        "deviationService",
        "selectedAgreementLines",
        "enableOpenImageListCallback"
    ];

    function imageListModalController(
        $q,
        $scope,
        $kWindow,
        $rootScope,
        $windowInstance,
        $timeout,
        $log,
        orderId,
        routeStop,
        deviationService,
        selectedAgreementLines,
        enableOpenImageListCallback
    ) {
        $scope.imageThumbnails = [];
        $scope.orderId = orderId;
        $scope.routeStop = routeStop;
        $scope.selectedAgreementLines = selectedAgreementLines;
        var enableModalOpen = true;
        initController();

        function initController() {
            loadImageThumbnails();
            $timeout(enableOpenImageListCallback, 200);
        }

        $rootScope.$on("deviationWindowClosed", () => {
            $windowInstance.close();
        });

        $scope.close = function() {
            $windowInstance.close();
        };

        $scope.openImageDialog = openImageDialog;

        $scope.deleteImage = function(evt, image) {
            preventPropagation(evt);
            deviationService
                .removeImageFromCacheByTimeStamp(
                    $scope.orderId,
                    image.timestamp
                )
                .then(() => {
                    loadImageThumbnails(true);
                });
        };

        $scope.openPhoto = function() {
            if (enableModalOpen) {
                enableModalOpen = false;
                var mobileDetect = new MobileDetect(window.navigator.userAgent);
                var forceImageFileInput =
                    (mobileDetect.phone() ||
                        mobileDetect.mobile() ||
                        mobileDetect.tablet()) &&
                    !mobileDetect.is("AndroidOS");

                if (forceImageFileInput) {
                    imageFileInputClick();
                } else {
                    $rootScope
                        .hasMediaDevice()
                        .then(function(userHasMediaDevice) {
                            if (userHasMediaDevice) {
                                openImageDialog(true, null, false);
                            } else {
                                imageFileInputClick();
                            }
                        });
                }
            }
        };

        function openImageDialog(videoMode, image, viewPhotoOnly) {
            var imageWindow = $kWindow.open({
                options: {
                    modal: true,
                    title: "Avvik",
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 580,
                    width: 640,
                    deactivate: function() {
                        this.$angular_scope.$parent.close();
                    }
                },
                templateUrl: "app/routeStops/modals/camera-modal-view.html",
                windowTemplateUrl: "app/shared/modal-base.html",
                controller: "CameraModalController",
                resolve: {
                    videoMode: function() {
                        return videoMode;
                    },
                    image: function() {
                        return image;
                    },
                    viewPhotoOnly: () => viewPhotoOnly,
                    enableImageModalOpenCallback: () => {
                        return setImageModalOpen;
                    }
                }
            });

            var imageHash;
            imageWindow.result
                .then(function(result) {
                    $rootScope.$broadcast("showBusyIndicator");
                    if (result) {
                        imageHash = result;
                        return deviationService.saveImageToCache(
                            $scope.orderId,
                            $scope.routeStop.routeLineId,
                            $scope.selectedAgreementLines,
                            imageHash
                        );
                    }
                })
                .then(function() {
                    $rootScope.$broadcast("hideBusyIndicator");
                    if (imageHash) {
                        $scope.unitsWithPicture = _.uniq(
                            _.concat(
                                $scope.unitsWithPicture,
                                $scope.selectedAgreementLines
                            )
                        );
                        loadImageThumbnails();
                    }
                })
                .finally(() => {
                    $rootScope.$broadcast("hideBusyIndicator");
                });
        }

        function setImageModalOpen() {
            enableModalOpen = true;
        }

        function imageFileInputClick() {
            var $openPhotoBtn = $("#openPhotoBtn");
            var imageInputId = "image-upload";
            var imageInputSelector = "#" + imageInputId;

            if (!$(imageInputSelector).length)
                $openPhotoBtn.after(
                    '<input id="' +
                        imageInputId +
                        '" type="file" style="display: none;" accept="image/*"/>'
                );

            $(imageInputSelector).click();
            $(imageInputSelector)
                .off()
                .on("change", function(e) {
                    var image = e.target.files[0];
                    if (image) openImageDialog(false, image, false);
                });
        }

        function preventPropagation(evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }

        function loadImageThumbnails(preventAutoOpenInput) {
            $rootScope.$broadcast("showBusyIndicator");
            deviationService
                .getImagesFromCache(
                    $scope.orderId,
                    $scope.routeStop.routeLineId
                )
                .then(function(images) {
                    setTimeout(() => {
                        var listWindowClass = "image-list-window";
                        if (
                            !$(".k-widget.k-window")
                                .last()
                                .hasClass(listWindowClass)
                        ) {
                            $(".k-widget.k-window")
                                .last()
                                .addClass(listWindowClass);
                        }
                    }, 0);
                    if (images) {
                        $scope.imageThumbnails = _.uniq(_.flatten(images));

                        if (
                            $scope.imageThumbnails &&
                            $scope.imageThumbnails.length > 0
                        ) {
                            _.forEach($scope.imageThumbnails, thumbnail => {
                                thumbnail.unitAddresses = [];
                                _.forEach($scope.routeStop.units, unit => {
                                    var index = -1;
                                    for (
                                        var i = 0;
                                        i < unit.agreementLines.length;
                                        i++
                                    ) {
                                        if (
                                            _.findIndex(
                                                thumbnail.agreementLineIds,
                                                id =>
                                                    id ===
                                                    unit.agreementLines[i]
                                            ) > -1
                                        ) {
                                            index = i;
                                            break;
                                        }
                                    }
                                    if (index > -1) {
                                        thumbnail.unitAddresses.push(
                                            unit.address
                                        );
                                    }
                                });
                            });
                            $rootScope.$broadcast("hideBusyIndicator");
                        } else {
                            $rootScope.$broadcast("hideBusyIndicator");
                            if (!preventAutoOpenInput) {
                                $scope.openPhoto();
                            }
                        }
                    }else{
                        $rootScope.$broadcast("hideBusyIndicator");
                    }
                });
        }
    }
})();
