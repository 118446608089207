(function () {
    'use strict';
    angular.module('PWAPoCApp').directive('routeStopRowContainer', routeStopRowContainer);
    routeStopRowContainer.$inject = ['orderTypeService'];
    var fullRowElements = [
        "AgreementDetailData_Name",
        "AgreementDetailData_Address1",
        "AgreementLineDetailData_Message",
        "DetailData_Message"
    ];
    function routeStopRowContainer(orderTypeService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/route-stop-row-container.html',
            transclude: true,
            link: link
        };
        return directive;
        function link(scope, element, attrs) {
            scope.defaultIfEmpty = function (data) {
                return data ? data : "-";
            };
            scope.splitSource = function (sourceItems, index) {
                var endInd = Math.floor(sourceItems.length + 1) / 2;
                var part = index === 0 ? sourceItems.slice(0, endInd) : sourceItems.slice(endInd, sourceItems.length);
                return part;
            };
            scope.displayFilter = function (sourceItems) {
                return _.reject(sourceItems, s => _.some(fullRowElements, kN => kN === s.key));
            };
            scope.filterForKeys = function (orderData) {
                var fullRowItems = _.filter(orderData, od => _.some(fullRowElements, key => od.key === key));
                var comment = _.find(fullRowItems, frItem => frItem.key === "DetailData_Message");
                var plassering = _.find(fullRowItems, frItem => frItem.key === "AgreementLineDetailData_Message");
                if (comment) {
                    comment.description = "Kommentar";
                }
                if (plassering) {
                    plassering.description = "Plassering";
                }
                return _.sortBy(fullRowItems, frItem => _.findIndex(fullRowElements, element => element === frItem.key));
            };
        }
    }
})();
