(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .directive("traditionalOrders", traditionalOrders);

    traditionalOrders.$inject = ["$rootScope", "settingsService"];

    function traditionalOrders($rootScope, settingsService) {
        var directive = {
            restrict: "E",
            replace: true,
            templateUrl:
                "app/orders/traditional-orders-view.html",
            transclude: true,
            link: link
        };

        return directive;

        function link(scope) {
           
        }
    }
})();
