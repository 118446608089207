(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('orderTypeService', orderTypeService);

    orderTypeService.$inject = [];

    function orderTypeService() {
        var service = {
           getOrderTypeName:getOrderTypeName
        }

        return service;

       
        function getOrderTypeName(orderTypeId){
            switch(orderTypeId){
                case 0: {
                    return 'Normal ordre';
                }
                case 1: {
                    return 'Anropsordre';
                }
                case 2: {
                    return 'Flytteordre';
                }
                case 3:{
                    return 'Tilgang / Utsett';
                }
                case 4:{
                    return 'Tømming';
                }
                case 5:{
                    return 'Avslutning / Innhent';
                }
                case 6:{
                    return 'Dynamisk ordre';
                }
                default:{
                    return 'Normal ordre';
                }
            }
        }

    }
})();
