(function() {
    angular
        .module("PWAPoCApp")
        .controller("DataButtonModalController", dataButtonModalController);

    dataButtonModalController.$inject = [
        "$timeout",
        "$scope",
        "$windowInstance",
        "dataButton"
    ];

    function dataButtonModalController(
        $timeout,
        $scope,
        $windowInstance,
        dataButton
    ) {
        $scope.numbers = "";

        $scope.addComma = addComma;
        $scope.close = close;
        $scope.save = save;

        initController();

        function initController() {
            if (dataButton.value) {
                $scope.numbers = dataButton.value;
            }
        }

        function addComma() {
            if ($scope.numbers.indexOf(",") === -1) {
                $scope.numbers += ",";
            }
        }

        function close() {
            $windowInstance.close({
                action: "cancel",
                value: null
            });
        }

        function save() {
            $windowInstance.close({
                action: "save",
                value: $scope.numbers
            });
        }
    }
})();
