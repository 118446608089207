(function () {
    'use strict';

    var pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.constant('orderDateFilterOptions', [
        { value: 'all', displayName: 'Alle' },
        { value: 'thisWeek', displayName: 'Denne uke' },
        { value: 'thisMonth', displayName: 'Denne måneden' },
    ]);
})();
