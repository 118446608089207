(function () {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("OrdersController", ordersController);

    ordersController.$inject = [
        "$q",
        "$timeout",
        "$state",
        "$rootScope",
        "$scope",
        "$kWindow",
        "$log",
        "networkService",
        "ordersService",
        "cacheService",
        "orderStatusTransitions",
        "routeStopsService",
        "routeStopUpdateChecker",
        "expirationService",
        "logService",
        "settingsService",
        "bluetoothService",
        "receiptTemplateService",
        "cacheMergeService",
        "containerOrdersService"
    ];

    function ordersController(
        $q,
        $timeout,
        $state,
        $rootScope,
        $scope,
        $kWindow,
        $log,
        networkService,
        ordersService,
        cacheService,
        orderStatusTransitions,
        routeStopsService,
        routeStopUpdateChecker,
        expirationService,
        logService,
        settingsService,
        bluetoothService,
        receiptTemplateService,
        cacheMergeService,
        containerOrdersService
    ) {
        $scope.orders = [];
        $scope.loadStatus = "";

        $scope.canDelete = canDelete;
        $scope.isVisible = isVisible;
        $scope.orderClicked = orderClicked;
        $scope.needsAdjust = false;
        $scope.takePart = takePart;
        if (navigator.userAgent.search("SamsungBrowser") >= 0) {
            //Samsung browser is not supported, we recommend you to use chrome, otherwise the application might not work correctly
            var samsungBrowserNotSupportedMsg =
                "Samsung internett nettleser er ikke støttet, vi anbefaler at du bruker Chrome. Det kan forekomme at applikasjonen ikke fungerer som den skal i Samsung internett nettleser";
            alert(samsungBrowserNotSupportedMsg);
        }

        initController();

        function initController() {
            settingsService.getEnableWeight().then(enableWeight => {
                if (enableWeight) {
                    bluetoothService.attachBotekWeightUnitDevice();
                }
            });

            settingsService.getPrinterEnabled().then(printerEnabled => {
                if (printerEnabled) {
                    receiptTemplateService.getReceiptTemplate(true);
                    return bluetoothService.attachZQ510Printer();
                }
            });
            settingsService.getIsContainerUser().then(isContainerUser => {
                $rootScope.isContainerUser = isContainerUser;

                $rootScope.$broadcast("showBusyIndicator");
                cacheMergeService
                    .moveKeysToLowerCaseUsername()
                    .then(() => {
                        $rootScope.$broadcast("hideBusyIndicator");
                        loadOrders();
                        getInitialZoom();
                        $scope.$on("refreshOrders", function () {
                            loadOrders();
                        });
                    })
                    .catch(() => {
                        $rootScope.$broadcast("hideBusyIndicator");
                    });
            });
        }

        function takePart(name, index) {
            var parts = name.split(",");
            if (index === 0) {
                return parts[0];
            }
            parts.shift();
            return parts.join(",");
        }

        function openSetDriverModal() {
            var deferred = $q.defer();

            settingsService.getDriverList().then(driverList => {
                var activeDrivers = _.filter(driverList, "active");
                if (
                    driverList &&
                    driverList.length > 0 &&
                    activeDrivers.length
                ) {
                    var driverListWindow = $kWindow.open({
                        options: {
                            width: 400,
                            height: 500,
                            modal: true,
                            title: "Velg sjåfør",
                            draggable: false,
                            movable: false,
                            resizable: false,
                            visible: false,
                            close: function () {
                                $rootScope.$broadcast("hideBusyIndicator"); //hide indicator when closed with 'x' button
                            }
                        },
                        templateUrl:
                            "app/orders/modals/set-driver-modal-view.html",
                        windowTemplateUrl: "app/shared/modal-base.html",
                        controller: "SetDriverModalController",
                        resolve: {
                            driverList: () => activeDrivers
                        }
                    });

                    driverListWindow.result
                        .then(() => {
                            deferred.resolve();
                        })
                        .finally(() => {
                            deferred.reject();
                        });
                } else {
                    deferred.resolve();
                }
            });
            return deferred.promise;
        }

        function getInitialZoom() {
            var deferred = $q.defer();

            cacheService.get("cachedZoomLevel").then(
                function (cachedZoomLevel) {
                    $rootScope.cachedZoomLevel = cachedZoomLevel;
                    deferred.resolve();
                },
                function (err) {
                    $log.error("getting zoom level from cache" + err);
                    deferred.resolve();
                }
            );

            return deferred.promise;
        }

        function canDelete(order) {
            return (
                order.isRouteCached &&
                isVisible(order) &&
                new Date(order.orderDate) < moment().startOf("day")
            );
        }

        function isVisible(order) {
            return order.transitionId !== 2 && order.transitionId !== 3;
        }

        function navigateToRouteStopList(order) {
            openSetDriverModal()
                .then(() => {
                    $state.go("main.routeStops", {
                        orderId: order.orderId,
                        sortBy: routeStopsService.getSortBy(),
                        splitView: routeStopsService.getSplitViewStatus(),
                        status: "uncompleted"
                    });
                })
                .catch(() => {
                    alert("Velg sjåfør før rute kan lastes");
                });
        }

        function isMyContainerOrder(order){
            return $rootScope.userType === "container" 
            && (order.vehicleId && $rootScope.authData && order.vehicleId.toLowerCase() === $rootScope.authData.username.toLowerCase());
        }

        function orderClicked(order) {
            if (!$rootScope.orderDeleteMode || !canDelete(order)) {
                if ($rootScope.userType === "container" && (!order.vehicleId || order.vehicleId.toLowerCase() !== $rootScope.authData.username.toLowerCase())){
                    alert('orders need to be assigned first');
                    return;
                }

                if (order.isRouteCached || isMyContainerOrder(order)) {
                    navigateToRouteStopList(order);
                } else {
                    networkService.isOnline().then(function (isOnline) {
                        if (isOnline) navigateToRouteStopList(order);
                        else
                            alert(
                                "Cannot check out order while offline - please check your connection"
                            );
                    });
                }

            } else {
                if (confirm("Er du sikker?")) {
                    $rootScope.$broadcast("showBusyIndicator");
                    ordersService
                        .updateOrderStatus(
                            order.orderId,
                            orderStatusTransitions.delete
                        )
                        .then(function () {
                            return routeStopUpdateChecker.scheduleUpdates(
                                order.orderId
                            );
                        })
                        .then(function () {
                            return routeStopsService.queueRouteDelete(
                                order.orderId
                            );
                        })
                        .then(function () {
                            $log.warn("order deleted by user" + order.orderId);
                            return ordersService.queueOrderDelete(
                                order.orderId
                            );
                        })
                        .then(loadOrders)
                        .finally(function () {
                            $rootScope.orderDeleteMode = false;
                            $rootScope.$broadcast("hideBusyIndicator");
                        });
                }
            }
        }

        function adjustColumnSizeToContent() {
            var maxFractionLen = 0;
            const resizeTreshold = 30;
            _.forEach($scope.orders, function (order) {
                var len = order.fractions.length;
                if (maxFractionLen < len) {
                    maxFractionLen = len;
                }
            });
            if (maxFractionLen > resizeTreshold) {
                return true;
            }
            return false;
        }

        //Private functions
        function hideOldOrdersForUndergroundUsers(orders) {
            if ($rootScope.userType === "underground") {
                return _.reject(
                    orders,
                    o =>
                        moment(o.orderDate, "YYYY-MM-DDTHH:mm:ss") <
                        moment().add(-1, "day")
                );
            }
            return orders;
        }

        function peekRouteStopCompletionProgress(order) {
            var deferred = $q.defer();
            if (order.isRouteCached) {
                cacheService
                    .get("_routeStops_" + order.orderId)
                    .then(route => {
                        if (
                            route &&
                            route.routeStops &&
                            route.routeStops.length
                        ) {
                            var routeStops = route.routeStops;
                            if (routeStops[0].system === "S") {
                                var stopsCount = routeStops.length;
                                var completedStopsCount =
                                    stopsCount -
                                    _.filter(routeStops, {
                                        status: "uncompleted"
                                    }).length;

                                deferred.resolve({
                                    stopsCount,
                                    completedStopsCount
                                });
                            } else {
                                deferred.resolve(null);
                            }
                        } else {
                            deferred.resolve(null);
                        }
                    })
                    .catch(() => {
                        deferred.resolve(null);
                    });
            } else {
                deferred.resolve(null);
            }
            return deferred.promise;
        }

        function loadOrders() {
            var deferred = $q.defer();

            $rootScope.$broadcast("showBusyIndicator");
            var currentOrders = [];

            var deleteExpiredOrders = $rootScope.isContainerUser ? $q.when : expirationService.deleteExpiredOrders;

            deleteExpiredOrders()
                .catch(function () {
                    /* ignore errors */
                })
                .then(function () {
                    if ($rootScope.isContainerUser) {
                        return containerOrdersService.getContainerOrders($rootScope.isOnline);
                    } else {
                        return ordersService.getOrders(true);
                    }
                })
                .then(function (orders) {
                    currentOrders = orders;
                    $rootScope.$broadcast("ordersLoaded");

                    var actualOrders = hideOldOrdersForUndergroundUsers(orders);
                    $scope.orders = _.orderBy(
                        actualOrders,
                        ["expiresDate", "orderType", "routeName"],
                        ["asc", "asc", "asc"]
                    );
                    $scope.loadStatus = "loaded";

                    if (_.some($scope.orders, ["isRouteCached", false])) {
                        cacheService.set(
                            "orderDetailTypesWndWasOpened",
                            "false"
                        );
                    }
                    
                    $rootScope.$broadcast('connectionChanged', $rootScope.isOnline ? "online" : "offline");
                    $scope.needsAdjust = adjustColumnSizeToContent();

                    var peekOrderPromises = _.map($scope.orders, function (o) {
                        return peekRouteStopCompletionProgress(o);
                    });

                    return $q.all(peekOrderPromises);
                })
                .then(orderProgresses => {
                    for (var i = 0; i < orderProgresses.length; i++) {
                        if (orderProgresses[i]) {
                            $scope.orders[i].progress = orderProgresses[i];
                        }
                    }
                    deferred.resolve();

                    return expirationService.deleteOrphanedReportedRouteStops(
                        currentOrders
                    );
                })
                .catch(function () {
                    $scope.loadStatus = "failed";
                    deferred.reject();
                })
                .finally(function () {
                    $rootScope.$broadcast("hideBusyIndicator");
                    return expirationService.uploadOrphanedRoutes();
                })
                .finally(function () {
                    expirationService.retryUploadingMarkedForUploads();
                });

            // temporary dependency
            logService.deleteOldLogs();

            return deferred.promise;
        }
    }
})();
