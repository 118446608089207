(function() {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("RouteStopsController", routeStopsController);

    routeStopsController.$inject = [
        "$rootScope",
        "$scope",
        "$state",
        "$window",
        "$timeout",
        "appSettings"
    ];

    function routeStopsController(
        $rootScope,
        $scope,
        $state,
        $window,
        $timeout,
        appSettings
    ) {
        $scope.routeStopsSettings = appSettings.routeStops;
        $scope.splitter = null;
        $scope.routeStopsSplitViewStatus = $state.params.splitView;

        initController();

        function initController() {
            angular.element($window).bind("resize", function() {
                handleSplitViewStatus();
            });

            $(document).on("click", ".k-splitbar", function() {
                var newFirstPaneWidth =
                    $(".k-pane:last").width() > 0
                        ? "100%"
                        : $scope.routeStopsSettings.splitterClickFirstPaneSize;
                $scope.splitter.size(".k-pane:first", newFirstPaneWidth);
            });

            $scope.splitterOptions = {
                orientation:
                    $scope.routeStopsSplitViewStatus === "horizontal" ||
                    $scope.routeStopsSplitViewStatus === "vertical"
                        ? $scope.routeStopsSplitViewStatus
                        : "horizontal",
                panes: $scope.routeStopsSettings.panes,
                resize: onResize
            };

            $timeout(0).then(handleSplitViewStatus);
        }

        function onResize() {
            $rootScope.$broadcast("splitterMoved");
        }

        function handleSplitViewStatus() {
            if ($scope.routeStopsSplitViewStatus === "map") {
                $scope.splitter.size(".k-pane:first", "0%");
            } else if ($scope.routeStopsSplitViewStatus === "list") {
                $scope.splitter.size(".k-pane:first", "100%");
            } else {
                try {
                    $scope.splitter.size(
                        ".k-pane:first",
                        $scope.routeStopsSettings.splitterClickFirstPaneSize
                    );
                } catch (err) {
                    console.log("kendo splitter destroy error: " + err);
                }
            }
        }
    }
})();
